/* tslint:disable */
/* eslint-disable */
/**
 * User Facing | GRID API
 * This is the user facing API for the GRIMME Identity Platform.       It provides the necessary endpoints for the user to interact with the platform.
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: digihub@grimme.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export * from "./api";
export * from "./configuration";

