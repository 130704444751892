import { MachineMapping } from '@mygrimme/types';
import { MachineDto, SharedMachineDto } from '~/gen/grid-user';
import { LocalMachine, Machine } from '../utils';

// NOTE: Is there a better place to put these types?
export type MachineWithSharingContract = Machine & {
  machineSharingContract?: SharedMachineDto['machineSharingContract'];
};
type MachineDtoWithSharingContract = MachineDto & {
  machineSharingContract?: SharedMachineDto['machineSharingContract'];
};

export class MachineMapper {
  static MapMachineMappingToMachine(machineMapping: MachineMapping): Machine {
    return {
      isTelemetryActive: !!machineMapping.IsTelemetryActive,
      // Not defined in old api
      machineStatus: undefined,
      manufacturingYear: machineMapping.ManufacturingYear,
      name: machineMapping.Name,
      serial: machineMapping.MachineSerialNumber,
    };
  }
  static MapGridToMachine(
    machine: MachineDtoWithSharingContract,
  ): MachineWithSharingContract {
    return {
      isTelemetryActive: machine.hasGsc && machine.status === 'active',
      machineSharingContract: machine.machineSharingContract,
      machineStatus: machine.status,
      manufacturingYear: machine.yearOfConstruction,
      name: machine.customMachineName || machine.model,
      serial: machine.serialNumber,
    };
  }

  static MapLocalMachineToMachine(machine: LocalMachine): Machine {
    return {
      isTelemetryActive: !!machine.GscSerialNumber,
      // Not defined in old api
      machineStatus: undefined,
      manufacturingYear: machine.ManufacturingYear,
      name: machine.Item?.Name,
      serial: machine.SerialNumber ?? '',
    };
  }

  static MapGridToMachineMapping(machine: MachineDto): MachineMapping {
    return {
      IsTelemetryActive: machine.hasGsc && machine.status === 'active',
      MachineSerialNumber: machine.serialNumber,
      ManufacturingYear: machine.yearOfConstruction,
      Name: machine.customMachineName || machine.model,
    };
  }
}
