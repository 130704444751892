import {
  getPathSlugs,
  GetStaticPropsInterface,
  getTranslatedMetaDataIn,
  StaticSlugsProps,
} from '~/utils/seo';

export const RoutesPath = {
  machineDetails: 'machine',
};

export async function getStaticPaths() {
  const paths: StaticSlugsProps[] = getPathSlugs();
  return {
    fallback: false,
    paths,
  };
}

export async function getStaticProps({ params }: { params: any }) {
  return {
    props: {
      ...params,
      ...getTranslatedMetaDataIn(params.locale),
    } as GetStaticPropsInterface,
  };
}
