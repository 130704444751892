import {
  AllInOneProfileData,
  MachineDocumentListResponse,
  MachineMapping,
  SerialMachineData,
} from '@mygrimme/types';
import { CompanyRoleDto, MachineDto, SharedMachineDto } from '~/gen/grid-user';
import { TelemetryOnlineStatus } from '~/utils/ApiQueryTypes';

export enum loadingStatus {
  NOT_LOADED = 'not loaded',
  LOADING = 'loading',
  LOADED = 'loaded',
  ERROR = 'error',
}

export type AllInOneProfileDataWithRoles = AllInOneProfileData & {
  CompanyRoles?: CompanyRoleDto[];
  Id?: string;
  Roles?: CompanyRoleDto[];
};

// This unifies the old and new profile calls.
export type UserProfile = Omit<
  AllInOneProfileDataWithRoles,
  'Permissions' | 'Companies'
> & {
  Companies?: {
    BusinessRelationId?: string | null;
    City?: string;
    Country?: string;
    FavoriteDealerBusinessRelationId?: string | null;
    Id: string;
    MachineMappings?: MachineMapping[];
    Name?: string;
    Street?: string;
    ZipCode?: string;
  }[];
  Permissions: { key: string; name: string }[];
};

type ArrayElement<ArrayType extends readonly unknown[] | undefined> =
  ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

export type ProfileWithoutMachineMappings = Omit<
  AllInOneProfileData,
  'Companies'
> & {
  Companies?: Array<Partial<ArrayElement<AllInOneProfileData['Companies']>>>;
};

export type LocalMachine = SerialMachineData & {
  documents?: Record<string, MachineDocumentListResponse>;
};

// This is a combined type of old and new API responses (MachineDto & MachineMapping)
export type Machine = {
  isTelemetryActive: boolean;
  machineSharingContract?: SharedMachineDto['machineSharingContract'];
  machineStatus?: MachineDto['status'];
  manufacturingYear?: number;
  name?: string;
  serial: string;
  telemetryStatus?: TelemetryOnlineStatus;
};
