import { RepurchaseEnquiry } from '@grimme/types';
import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { environment } from '../environments';

export class OnlineSalesApi {
  private http: AxiosInstance;
  constructor() {
    this.http = axios.create({ baseURL: environment.onlineSalesApiUrl });
  }

  async postRepurchaseValueEnquiry(
    data: RepurchaseEnquiry,
    locale: string,
  ): Promise<AxiosResponse<unknown, unknown>> {
    return this.http.post('/enquiry/repurchase-value', data, {
      params: { lang: locale },
    });
  }
}
