/* tslint:disable */
/* eslint-disable */
/**
 * Admin | GRID API
 * Manage users, machines and companies. The is the admin API of GRID. It is supposed to be used by the admin users through the admin interface. To be able to access the API, you need to be authenticated with a GRIMME Entra ID account and have the necessary roles. The minimum required role is `Read`. To write basic data, `WriteBasic` is needed. To write advanced data (Roles and Permissions), `WriteFull` is needed.
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: digihub@grimme.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export * from "./api";
export * from "./configuration";

