import axios from 'axios';
import {
  AxiosCacheInstance,
  buildWebStorage,
  setupCache,
} from 'axios-cache-interceptor';

export let cachedAxiosInstance: AxiosCacheInstance | null = null;

export const getCachedAxiosInstance = () => {
  if (!cachedAxiosInstance && typeof window === 'undefined') {
    cachedAxiosInstance = setupCache(axios.create(), { ttl: 1000 * 60 });
  }

  if (
    !cachedAxiosInstance &&
    typeof window !== 'undefined' &&
    typeof localStorage !== 'undefined'
  ) {
    cachedAxiosInstance = setupCache(axios.create(), {
      storage: buildWebStorage(localStorage, 'axios-cache:'),
      ttl: 1000 * 60,
    });
  }

  return cachedAxiosInstance;
};
