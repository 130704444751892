import { notificationsActions, notificationType } from '@grimme/components';
import {
  createAction,
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { captureException } from '@sentry/nextjs';
import { MachineMapping, UserCompanyData } from '@mygrimme/types';
import {
  CompanyRoleDto,
  MachineDto,
  PatchUserFacingCompanyDto,
  Permission,
  UserDto,
  UserRolesResponseDto,
} from '~/gen/grid-user';
import { signOut } from '~/utils/auth/signOut';
import { DEFAULT_NOTIFICATION_MESSAGE } from '~/utils/consts';
import { userGridApi } from '../utils/rest-apis/grid/api';
import {
  addMachine,
  deleteMachineGrid,
  editMachineGrid,
} from './machines-v2.slice';
import { MachineMapper, UserMapper } from './utils/mappers';
import { loadingStatus, UserProfile } from './utils/utils';

export const AUTH_FEATURE_KEY = 'auth';

/*
  tokenResponse expiry dates have been serialized to unix timestamp
  if the date values need to be read, use new Date(extResponse)
*/

export type AuthState = {
  error?: string;
  isLoadingUser: boolean;
  isSettingFavoriteDealer: boolean;
  isUpdatingCompany: boolean;
  loadingStatus?: loadingStatus;
  user?: UserProfile;
};

const errorNotification = notificationsActions?.addErrorNotification({
  code: notificationType.DEFAULT,
  message: 'myGRIMME_core_account_form_failure',
});

const successNotification = notificationsActions?.addSuccessNotification({
  code: notificationType.DEFAULT,
  message: 'myGRIMME_core_account_form_success',
});

export const fetchMe = createAsyncThunk(
  'auth/me',
  async ({ accessToken }: { accessToken: string }, { dispatch }) => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      let user = {} as UserDto;
      let permissions = [] as Permission[];
      let companyRoles = [] as CompanyRoleDto[];
      let userSpecificData = {} as UserRolesResponseDto;
      let userMachines: MachineDto[] = [];

      try {
        const { data } = await userGridApi.me.currentUserControllerGet({
          headers,
        });

        user = data;
      } catch (error) {
        dispatch(
          notificationsActions.addErrorNotification({
            code: notificationType.DEFAULT,
            message: 'myGRIMME_core_profile_fetch_failure',
          }),
        );
        captureException(error);
        signOut();
        return;
      }

      try {
        const { data } =
          await userGridApi.machinesApi.currentUserMachinesControllerGetAllMachines(
            {
              headers,
            },
          );
        userMachines = data;
      } catch (error) {
        captureException(error);
        console.error('Error fetching user machines', error);
      }
      //TODO: Move these try catch blocks to another file;
      try {
        const { data: permissionsData } =
          await userGridApi.me.currentUserControllerGetPermissions({
            headers,
          });
        permissions = permissionsData?.data;
      } catch (error) {
        captureException(error);
        console.error('Error fetching permissions', error);
      }

      try {
        const { data: rolesData } =
          await userGridApi.company.currentUserCompanyControllerGetRoles({
            headers,
          });
        companyRoles = rolesData?.data;
      } catch (error) {
        captureException(error);
        console.error('Error fetching all possible roles', error);
      }

      try {
        const { data: userData } =
          await userGridApi.me.currentUserControllerGetRoles({
            headers,
          });
        userSpecificData = userData;
      } catch (error) {
        captureException(error);
        console.error('Error fetching user-specific data', error);
      }

      const userSpecificRoles = companyRoles?.filter((itemA) =>
        userSpecificData?.data?.some((itemB) => itemB.name === itemA.name),
      );

      // TODO: grid uses UserDto instead of AllInOneProfileData;
      // Status field below is an object. Previously we used this
      // field to determine status; since types are completely
      // different now and new status field is named accountStatus,
      // which is a string, this code below is a workaround;
      // in the future, once grid is already there just remove Status
      // and implement accountStatus, UI is prepared to handle it.
      // see status.tsx file.
      return UserMapper.MapGridToUser(
        user,
        permissions,
        companyRoles,
        userSpecificRoles,
        userMachines,
      );
    } catch (error) {
      captureException(error);
      dispatch(
        notificationsActions.addErrorNotification({
          code: notificationType.DEFAULT,
          message: DEFAULT_NOTIFICATION_MESSAGE.myGRIMME_core_login_failure,
        }),
      );
    }
  },
);

export const setCompanyData = createAction(
  'auth/setCompany',
  (company: UserCompanyData) => ({ payload: company }),
);

export const updateProfileGrid = createAsyncThunk(
  'auth/updateProfileGrid',
  async (
    {
      accessToken,
      payload: { firstName, lastName, phone, preferredLanguageCode },
      suppressNotifications,
    }: {
      accessToken: string;
      payload: {
        firstName?: string;
        lastName?: string;
        phone?: string;
        preferredLanguageCode?: string;
      };
      suppressNotifications?: boolean;
    },
    { dispatch },
  ) => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await userGridApi.me.currentUserControllerPatch(
        {
          firstName,
          lastName,
          phone,
          preferredLanguageCode,
        },
        { headers },
      );

      if (!suppressNotifications) {
        dispatch(successNotification);
      }

      return response;
    } catch (error) {
      if (!suppressNotifications) {
        dispatch(errorNotification);
      }
    }
  },
);

export const updateCompanyGrid = createAsyncThunk(
  'auth/updateCompanyGrid',
  async (
    {
      accessToken,
      payload,
    }: {
      accessToken: string;
      payload: PatchUserFacingCompanyDto;
    },
    { dispatch },
  ) => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      await userGridApi.company.currentUserCompanyControllerPatchCompany(
        //TODO: Update api, for some reason these fields are mandatory.
        payload,
        { headers },
      );
      dispatch(successNotification);
    } catch (error) {
      dispatch(errorNotification);
    }
  },
);

export const updateFavouriteDealer = createAsyncThunk(
  'auth/updateFavouriteDealer',
  async (
    {
      accessToken,
      payload: { companyName, favoriteDealerId },
    }: {
      accessToken: string;
      payload: {
        companyName?: string;
        favoriteDealerId?: string;
      };
    },
    { dispatch },
  ) => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      await userGridApi.company.currentUserCompanyControllerPatchCompany(
        {
          dealerBusinessRelationId: favoriteDealerId,
          name: companyName,
        },
        { headers },
      );
      dispatch(successNotification);
    } catch (error) {
      dispatch(errorNotification);

      throw error;
    }
  },
);

export const initialAuthState: AuthState = {
  error: undefined,
  isLoadingUser: false,
  isSettingFavoriteDealer: false,
  isUpdatingCompany: false,
  loadingStatus: loadingStatus.NOT_LOADED,
  user: undefined,
};

export const authSlice = createSlice({
  extraReducers: (builder) => {
    builder
      // ------ NEW GRID2.0 API ---- //
      .addCase(fetchMe.pending, (state: AuthState) => {
        state.isLoadingUser = true;
        state.loadingStatus = loadingStatus.LOADING;
        state.error = undefined;
      })
      .addCase(fetchMe.fulfilled, (state: AuthState, action) => {
        state.isLoadingUser = false;
        state.error = undefined;
        state.loadingStatus = loadingStatus.LOADED;
        state.user = action.payload;
      })
      .addCase(fetchMe.rejected, (state: AuthState, action) => {
        state.isLoadingUser = false;
        state.error = action.error.message;
        state.loadingStatus = loadingStatus.ERROR;
        state.user = undefined;
      })
      .addCase(updateProfileGrid.pending, (state: AuthState, action) => {
        state.isLoadingUser = true;
      })
      .addCase(updateProfileGrid.fulfilled, (state: AuthState, action) => {
        state.isLoadingUser = false;
        if (action.payload && action.payload.data) {
          if (state.user) {
            state.user.FirstName = action.payload.data.firstName;
            state.user.LastName = action.payload.data.lastName;
            state.user.Phone = action.payload.data.phone;
            state.user.Language = action.payload.data.preferredLanguageCode;
          }
        }
      })
      .addCase(updateProfileGrid.rejected, (state: AuthState, action) => {
        state.isLoadingUser = false;
        state.error = action.error.message;
      })
      .addCase(updateCompanyGrid.pending, (state: AuthState) => {
        state.isUpdatingCompany = true;
      })
      .addCase(updateCompanyGrid.fulfilled, (state: AuthState, action) => {
        state.isUpdatingCompany = false;

        const userCompany = state.user?.Companies?.[0];

        if (!userCompany) {
          return;
        }

        const { address, dealerBusinessRelationId, name } =
          action.meta.arg.payload;
        const { city, countryCode, street, zipCode } = address || {};

        userCompany.Name = name || userCompany.Name;
        userCompany.FavoriteDealerBusinessRelationId =
          dealerBusinessRelationId ||
          userCompany.FavoriteDealerBusinessRelationId;
        userCompany.City = city || userCompany.City;
        userCompany.ZipCode = zipCode || userCompany.ZipCode;
        userCompany.Country = countryCode || userCompany.Country;
        userCompany.Street = street || userCompany.Street;
      })
      .addCase(updateCompanyGrid.rejected, (state: AuthState) => {
        state.isUpdatingCompany = false;
      })
      .addCase(updateFavouriteDealer.pending, (state: AuthState, action) => {
        state.isSettingFavoriteDealer = true;
      })
      .addCase(updateFavouriteDealer.fulfilled, (state: AuthState, action) => {
        state.isSettingFavoriteDealer = false;

        const userCompany = state.user?.Companies?.[0];

        if (!userCompany) {
          return;
        }

        const { favoriteDealerId } = action.meta.arg.payload;

        userCompany.FavoriteDealerBusinessRelationId =
          favoriteDealerId || userCompany.FavoriteDealerBusinessRelationId;
      })
      .addCase(updateFavouriteDealer.rejected, (state: AuthState) => {
        state.isSettingFavoriteDealer = false;
      })
      .addCase(setCompanyData, (state: AuthState, action) => {
        if (state.user) {
          state.user.Companies = [action.payload];
        }
      });
  },
  initialState: initialAuthState,
  name: AUTH_FEATURE_KEY,
  reducers: {
    // ...
  },
});

export const authReducer = authSlice.reducer;

export const authActions = authSlice.actions;

export const getAuthState = (rootState: {
  [AUTH_FEATURE_KEY]: AuthState;
}): AuthState => rootState[AUTH_FEATURE_KEY];

export const getUser = createSelector(getAuthState, (state) => state.user);

export const userHasCompany = createSelector(
  getUser,
  (user) => !!user?.Companies?.[0]?.Id,
);

export const selectIsUserLoading = createSelector(
  getAuthState,
  (state) => state.loadingStatus === loadingStatus.LOADING,
);

export const selectIsUserLoaded = createSelector(
  getAuthState,
  (state) => state.loadingStatus === loadingStatus.LOADED,
);

export const selectIsUserError = createSelector(
  getAuthState,
  (state) => state.loadingStatus === loadingStatus.ERROR,
);

export const selectIsUserFetchFinished = createSelector(
  getAuthState,
  (state) =>
    state.loadingStatus === loadingStatus.LOADED ||
    state.loadingStatus === loadingStatus.ERROR,
);

export const selectIsUnauthorized = createSelector(
  getAuthState,
  (state) => state.error === 'Unauthorized Request',
);

export const selectFavoriteDealerId = createSelector(
  getAuthState,
  (state) => state.user?.Companies?.[0]?.FavoriteDealerBusinessRelationId,
);

export const selectIsSettingFavoriteDealer = createSelector(
  getAuthState,
  (state) => state.isSettingFavoriteDealer,
);
