import { Dealer, UserCompanyData } from '@mygrimme/types';
import {
  CompanyRoleDto,
  MachineDto,
  Permission,
  UserDto,
} from '~/gen/grid-user';
import { UserFormFields } from '~/pages-components/account/utils/types';
import { parsePhoneNumber } from '~/utils';
import { DEFAULT_COUNTRY_CODE } from '~/utils/consts';
import {
  AllInOneProfileDataWithRoles,
  ProfileWithoutMachineMappings,
  UserProfile,
} from '../utils';
import { MachineMapper } from './machine.mapper';
import { PermissionMapper } from './permission.mapper';

export class UserMapper {
  static MapGridToUser(
    user: UserDto,
    permissions: Permission[],
    companyRoles: CompanyRoleDto[],
    userSpecificRoles: CompanyRoleDto[],
    machines: MachineDto[],
  ): UserProfile {
    return {
      Companies: user.company
        ? [
            {
              BusinessRelationId: user.company.businessRelationId,
              City: user.company.address?.city,
              Country: user.company.address?.countryCode,
              FavoriteDealerBusinessRelationId:
                user.company.dealerBusinessRelationId,
              Id: user.company.id,
              MachineMappings: machines.map(
                MachineMapper.MapGridToMachineMapping,
              ),
              Name: user.company.name,
              Street: user.company.address?.street,
              ZipCode: user.company.address?.zipCode, // not in new grid profile call
            },
          ]
        : [],
      CompanyRoles: companyRoles,
      CreatedDateTime: user.createAt,
      Email: user.email,
      FirstName: user.firstName,
      Id: user.id,
      Language: user.preferredLanguageCode,
      LastName: user.lastName,
      MobilePhone: undefined, // TODO: missing param
      Permissions: permissions,
      Phone: user.phone,

      Roles: userSpecificRoles,
      Status: {
        AccountVerfified: user.accountStatus === 'active',
        AddressCompleted: !!user.company?.address,
        // Email is not verified if it is declined or only invited;
        // then status should be pending. see again status.tsx file.
        EmailVerified: !(
          user.accountStatus === 'declined' || user.accountStatus === 'invited'
        ),
      },
    };
  }

  static MapOldProfileToUser(user: AllInOneProfileDataWithRoles): UserProfile {
    return {
      ...user,
      Permissions: (user?.Permissions ?? []).map(
        PermissionMapper.MapOldToGridPermission,
      ),
    };
  }

  static MapUserToOldProfile(user: UserProfile): AllInOneProfileDataWithRoles {
    return {
      ...user,
      Companies: user.Companies as UserCompanyData[],
      Permissions: (user?.Permissions ?? []).map(
        PermissionMapper.MapGridToOldPermission,
      ),
    };
  }

  static MapUserToProfileFormFields(user: UserProfile): UserFormFields {
    return {
      address: user?.Companies?.[0]?.Street || undefined,
      city: user?.Companies?.[0]?.City || undefined,
      company: user?.Companies?.[0]?.Name || undefined,
      country: user?.Companies?.[0]?.Country || undefined,
      email: user?.Email || undefined,
      firstName: user?.FirstName || undefined,
      lastName: user?.LastName || undefined,
      phone: parsePhoneNumber(
        user?.Phone,
        user?.Companies?.[0]?.Country || DEFAULT_COUNTRY_CODE,
      ),
      zipCode: user?.Companies?.[0]?.ZipCode || undefined,
    };
  }

  static MapUserToUpdateProfile(
    user: UserProfile,
    updatedFields?: {
      accountFormFields?: UserFormFields;
      favouriteDealerData?: Dealer;
      language?: string;
    },
  ): ProfileWithoutMachineMappings {
    const { accountFormFields, favouriteDealerData, language } =
      updatedFields || {};
    const profile = this.MapUserToOldProfile(user);

    let updatePayload = {
      ...profile,
      Companies: [
        {
          ...profile?.Companies?.[0],
          MachineMappings: undefined,
        },
      ],
    };

    // Don't need these fields.
    if (updatePayload && updatePayload?.Id) {
      delete updatePayload?.Id;
    }

    if (updatePayload && updatePayload?.Roles) {
      delete updatePayload?.Roles;
    }

    if (updatePayload && updatePayload?.CompanyRoles) {
      delete updatePayload?.CompanyRoles;
    }

    // Update Language
    if (language) {
      updatePayload.Language = language;
    }

    // Update fields
    if (accountFormFields || favouriteDealerData) {
      const permissionsFromUserProfile = updatePayload?.Permissions || [];
      const FirstName =
        accountFormFields?.firstName || updatePayload?.FirstName;
      const LastName = accountFormFields?.lastName || updatePayload?.LastName;
      const Permissions = accountFormFields?.permissions
        ? accountFormFields?.permissions.map(
            PermissionMapper.MapGridToOldPermission,
          )
        : permissionsFromUserProfile;
      const Phone =
        accountFormFields?.phone?.fullPhoneNumber || updatePayload?.Phone;
      const FavoriteDealerBusinessRelationId =
        favouriteDealerData?.Id ||
        updatePayload?.Companies?.[0]?.FavoriteDealerBusinessRelationId;
      const Name =
        accountFormFields?.company || updatePayload?.Companies?.[0]?.Name;
      const Street =
        accountFormFields?.address || updatePayload?.Companies?.[0]?.Street;
      const ZipCode =
        accountFormFields?.zipCode || updatePayload?.Companies?.[0]?.ZipCode;
      const City =
        accountFormFields?.city || updatePayload?.Companies?.[0]?.City;
      const Country =
        accountFormFields?.country || updatePayload?.Companies?.[0]?.Country;

      updatePayload = {
        ...updatePayload,
        Companies: [
          {
            BusinessRelationId:
              updatePayload?.Companies?.[0]?.BusinessRelationId,
            City,
            Country,
            FavoriteDealerBusinessRelationId,
            MachineMappings: undefined,
            Name,
            Street,
            ZipCode,
          },
        ],
        FirstName,
        LastName,
        Permissions,
        Phone,
      };
    }

    return updatePayload;
  }
}
