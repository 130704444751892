import { defaultLocale, description, locales, title } from '../gen';

export const META_TITLE_KEY = 'myGRIMME_core_meta_title';
export const META_DESCRIPTION_KEY = 'myGRIMME_core_meta_description';

interface MetaData {
  description: string;
  title: string;
}

export const getTranslatedMetaDataIn = (locale: string): MetaData => ({
  description: (description[locale] || description[defaultLocale])[
    META_DESCRIPTION_KEY
  ],
  title: (title[locale] || title[defaultLocale])[META_TITLE_KEY] || 'myGRIMME',
});

export interface GetStaticPropsInterface extends MetaData {
  locale: string;
}

export interface StaticSlugsProps {
  params: GetStaticPropsInterface;
}

export const getPathSlugs = (): StaticSlugsProps[] =>
  locales.map((locale) => ({
    params: {
      locale,
      ...getTranslatedMetaDataIn(locale),
    },
  }));
