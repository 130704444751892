import { signOut as ssoSignOut } from '@grimme/next-grimme-auth';
import { environment } from '../../environments';

export const signOut = (payload?: {
  authServer?: string;
  callbackUrl?: string;
}) =>
  ssoSignOut({
    authServer: payload?.authServer,
    callbackUrl: payload?.callbackUrl || environment.redirectUri,
  });
