import { TComponentData } from '@grimme/buttery';
import { environment } from '../../environments';

export enum ButterContent {
  CROP_COLORS = 'cropvarietycolors',
  CROP_TYPES = 'croptypes',
  MYGRIMME_INTEGRATION = 'mygrimme_integration',
}

export enum ButterPages {
  CONTACT_ASSISTANT_ICONS = 'contact-assistant-icons',
}

export type ButterData = {
  fields?: {
    body?: {
      fields?: {
        cta_button_label?: string;
        cta_button_url?: string;
        features?: {
          title: string;
        }[];
        headline?: string;
        image?: string;
        subheadline?: string;
        video_hq?: string;
        video_lq?: string;
      };
    }[];
    buttery_components: TComponentData[];
    seo: {
      description?: string;
      title?: string;
    };
  };
};

export const generateButterSlug = (
  language: string,
  slug: string,
  preview?: boolean | string | null,
) => {
  const url = new URL(`https://api.buttercms.com/v2/pages/*/${slug}`);
  url.searchParams.set('locale', language);
  url.searchParams.set('auth_token', environment.cmsAuthToken ?? '');
  if (preview) {
    url.searchParams.set('preview', preview.toString());
  }
  return url;
};

export const generateButterContentSlug = (language: string, slug: string) => {
  const url = new URL(`https://api.buttercms.com/v2/content/${slug}`);
  url.searchParams.set('locale', language);
  url.searchParams.set('auth_token', environment.cmsAuthToken ?? '');
  return url;
};
