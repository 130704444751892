// This file can be replaced during build by using the `fileReplacements` array.
// When building for production, this file is replaced with `environment.prod.ts`.
export type Environment = {
  accountUrl: string;
  agrirouterLoginUrl: string;
  agrirouterUrl: string;
  apiUrl: string;
  appEnv: string;
  appVersion?: string;
  authServer: string;
  azureAppConfigEndpoint?: string;
  cmsAuthToken?: string;
  commitHash?: string;
  connectionmanagerApiUrl: string;
  connectivityApiUrl: string;
  connectivityUrl: string;
  googleMapsApiKey?: string;
  gridUrl: string;
  grimmeWebsiteUrl: string;
  insightsKey?: string;
  maintenanceMode: boolean;
  myJobsUrl: string;
  newsletterSubscriptionApiUrl?: string;
  onlineSalesApiUrl: string;
  production: boolean;
  projectName?: string;
  redirectUri: string;
  registrationLink: string;
  remoteViewPort?: string;
  remoteViewUrl?: string;
  shopUrl: string;
  shopUrlUk: string;
  telemetryLink: string;
  translationApi: string;
  usedMachinesLink: string;
};

// some env vars have been injected via Webpack
// check here: apps/core/webpack.config.js
export const environment: Environment = {
  accountUrl:
    process.env.NEXT_PUBLIC_GRID_ACCOUNT_URL ||
    'https://tst.account.grimme.com',
  agrirouterLoginUrl: process.env.NEXT_PUBLIC_AGRIROUTER_LOGIN_LINK || '/',
  agrirouterUrl: process.env.NEXT_PUBLIC_AGRIROUTER_LINK || '/',
  apiUrl:
    process.env.NEXT_PUBLIC_MY_GRIMME_CORE_API || 'https://localhost:3334/api',
  appEnv: process.env.NEXT_PUBLIC_APP_ENV ?? 'local',
  appVersion: process.env.NEXT_PUBLIC_NPM_PACKAGE_VERSION,
  authServer:
    process.env.NEXT_PUBLIC_AUTH_SERVER || 'https://tst.auth.grimme.com',
  azureAppConfigEndpoint: process.env.NEXT_PUBLIC_AZURE_APP_CONFIG,
  cmsAuthToken: process.env.NEXT_PUBLIC_CMS_AUTH_TOKEN,
  commitHash: process.env.NEXT_PUBLIC_COMMIT_HASH,
  connectionmanagerApiUrl:
    process.env.NEXT_PUBLIC_CONNECTIONMANAGER_API_URL ||
    'https://tst.api.connectionmanager.grimme.com/api',
  connectivityApiUrl:
    process.env.NEXT_PUBLIC_CONNECTIVITY_API_URL ||
    'https://tst.api.connectivity.grimme.com/api',
  connectivityUrl:
    process.env.NEXT_PUBLIC_CONNECTIVITY_LINK ||
    'https://tst.connectivity.grimme.com',
  googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
  gridUrl:
    process.env.NEXT_PUBLIC_GRID_API_URL ??
    'https://tst.api.account.grimme.com',
  grimmeWebsiteUrl:
    process.env.NEXT_PUBLIC_GRIMME_WEBSITE_URL || 'https://grimme.com',
  insightsKey: process.env.NEXT_PUBLIC_APP_INSIGHTS_KEY,
  maintenanceMode: process.env.NEXT_PUBLIC_MAINTENANCE_MODE === 'true',
  myJobsUrl:
    process.env.NEXT_PUBLIC_MY_JOBS ||
    'https://tst.connectivity.grimme.com/jobs?filtering=[{"id":"machine","value":{"filters":[{"c":"=","cs":false,"v":{serial}}]}}]',
  newsletterSubscriptionApiUrl:
    process.env.NEXT_PUBLIC_NEWSLETTER_SUBSCRIPTION_API ||
    'https://dcj-email-service-tst.azurewebsites.net/api/v1/newsletter/subscription',
  onlineSalesApiUrl:
    process.env.NEXT_PUBLIC_ONLINE_SALES_API ??
    'https://online-sales-tst.azurewebsites.net/api',
  production: process.env.NODE_ENV === 'production',
  projectName: process.env.NEXT_PUBLIC_NPM_PACKAGE_NAME,
  redirectUri: process.env.NEXT_PUBLIC_REDIRECT_URI || 'http://localhost:4200',
  registrationLink:
    process.env.NEXT_PUBLIC_REGISTRATION_LINK ||
    'https://tst.accounts.grimme.com',
  remoteViewPort: process.env.NEXT_PUBLIC_REMOTE_VIEW_PORT || '443',
  remoteViewUrl:
    process.env.NEXT_PUBLIC_REMOTE_VIEW_URL ||
    'cci-staging-novnc.cc-isobus.com',
  shopUrl:
    process.env.NEXT_PUBLIC_GRIMME_SHOP_LINK || 'https://int.shop.grimme.com',
  shopUrlUk:
    process.env.NEXT_PUBLIC_GRIMME_UK_SHOP_LINK ||
    'https://www.shop.grimme-int.cloud.spryker.toys/en',
  telemetryLink:
    process.env.NEXT_PUBLIC_TELEMETRY_LINK ||
    'https://tst.connectivity.grimme.com/live-view/{serial}',
  translationApi:
    process.env.NEXT_PUBLIC_TRANSLATION_API ||
    'https://my-grimme-translations.azurewebsites.net/api',
  usedMachinesLink:
    process.env.NEXT_PUBLIC_USED_MACHINES_LINK ||
    'https://tst.marketplace.grimme.com/',
};
