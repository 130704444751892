import { FakePermission } from '@mygrimme/types';
import { Permission } from '~/gen/grid-user';

export class PermissionMapper {
  static MapOldToGridPermission(permission: FakePermission) {
    return {
      key: permission.Id,
      name: permission.Label,
    };
  }
  static MapGridToOldPermission(permission: Permission): FakePermission {
    return {
      Id: permission.key as any,
      Label: permission.name,
    };
  }
}
