/* tslint:disable */
/* eslint-disable */
/**
 * User Facing | GRID API
 * This is the user facing API for the GRIMME Identity Platform.       It provides the necessary endpoints for the user to interact with the platform.
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: digihub@grimme.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface ApproveMachineSharingContractResponseDto
 */
export interface ApproveMachineSharingContractResponseDto {
    /**
     * The serial number of the machine
     * @type {string}
     * @memberof ApproveMachineSharingContractResponseDto
     */
    'id': string;
    /**
     * Machine sharing contract status
     * @type {string}
     * @memberof ApproveMachineSharingContractResponseDto
     */
    'status': ApproveMachineSharingContractResponseDtoStatusEnum;
    /**
     * Party that initiated the sharing contract
     * @type {string}
     * @memberof ApproveMachineSharingContractResponseDto
     */
    'initiatedBy': ApproveMachineSharingContractResponseDtoInitiatedByEnum;
    /**
     * \"True\" if owner accepted machine sharing. When owner initiates sharing contract - consent is automatically set to \"True\"
     * @type {boolean}
     * @memberof ApproveMachineSharingContractResponseDto
     */
    'ownerConsent': boolean;
    /**
     * \"True\" if recipient accepted machine sharing. When recipient initiates sharing contract - consent is automatically set to \"True\"
     * @type {boolean}
     * @memberof ApproveMachineSharingContractResponseDto
     */
    'recipientConsent': boolean;
    /**
     * 
     * @type {MachineSharingContractMachineDto}
     * @memberof ApproveMachineSharingContractResponseDto
     */
    'machine': MachineSharingContractMachineDto;
    /**
     * 
     * @type {MachineSharingContractPartyDto}
     * @memberof ApproveMachineSharingContractResponseDto
     */
    'ownerCompany': MachineSharingContractPartyDto;
    /**
     * 
     * @type {MachineSharingContractPartyDto}
     * @memberof ApproveMachineSharingContractResponseDto
     */
    'recipientCompany': MachineSharingContractPartyDto;
    /**
     * 
     * @type {string}
     * @memberof ApproveMachineSharingContractResponseDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ApproveMachineSharingContractResponseDto
     */
    'updatedAt': string;
}

export const ApproveMachineSharingContractResponseDtoStatusEnum = {
    Pending: 'pending',
    Active: 'active'
} as const;

export type ApproveMachineSharingContractResponseDtoStatusEnum = typeof ApproveMachineSharingContractResponseDtoStatusEnum[keyof typeof ApproveMachineSharingContractResponseDtoStatusEnum];
export const ApproveMachineSharingContractResponseDtoInitiatedByEnum = {
    Owner: 'owner',
    Recipient: 'recipient'
} as const;

export type ApproveMachineSharingContractResponseDtoInitiatedByEnum = typeof ApproveMachineSharingContractResponseDtoInitiatedByEnum[keyof typeof ApproveMachineSharingContractResponseDtoInitiatedByEnum];

/**
 * 
 * @export
 * @interface BulkRemovalMachineSharingContractsRequestDto
 */
export interface BulkRemovalMachineSharingContractsRequestDto {
    /**
     * Ids of machine sharing contracts to remove
     * @type {Array<string>}
     * @memberof BulkRemovalMachineSharingContractsRequestDto
     */
    'machineSharingContractIds': Array<string>;
}
/**
 * 
 * @export
 * @interface CompanyAddressDto
 */
export interface CompanyAddressDto {
    /**
     * The company address city.
     * @type {string}
     * @memberof CompanyAddressDto
     */
    'city': string;
    /**
     * The company address country code.
     * @type {string}
     * @memberof CompanyAddressDto
     */
    'countryCode': string;
    /**
     * The company address street.
     * @type {string}
     * @memberof CompanyAddressDto
     */
    'street': string;
    /**
     * The company address zip code.
     * @type {string}
     * @memberof CompanyAddressDto
     */
    'zipCode': string;
}
/**
 * 
 * @export
 * @interface CompanyRoleDto
 */
export interface CompanyRoleDto {
    /**
     * 
     * @type {string}
     * @memberof CompanyRoleDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyRoleDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyRoleDto
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyRoleDto
     */
    'publicNameTranslationKey'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyRoleDto
     */
    'publicDescriptionTranslationKey'?: string | null;
}
/**
 * 
 * @export
 * @interface CompanyRolesResponseDto
 */
export interface CompanyRolesResponseDto {
    /**
     * 
     * @type {Array<CompanyRoleDto>}
     * @memberof CompanyRolesResponseDto
     */
    'data': Array<CompanyRoleDto>;
}
/**
 * 
 * @export
 * @interface CompanyUserDto
 */
export interface CompanyUserDto {
    /**
     * The user\'s unique identifier.
     * @type {string}
     * @memberof CompanyUserDto
     */
    'id': string;
    /**
     * The user\'s email.
     * @type {string}
     * @memberof CompanyUserDto
     */
    'email': string;
    /**
     * The user\'s first name.
     * @type {string}
     * @memberof CompanyUserDto
     */
    'firstName': string;
    /**
     * The user\'s last name.
     * @type {string}
     * @memberof CompanyUserDto
     */
    'lastName': string;
    /**
     * The user\'s phone number.
     * @type {string}
     * @memberof CompanyUserDto
     */
    'phone'?: string | null;
    /**
     * The user\'s preferred language code.
     * @type {string}
     * @memberof CompanyUserDto
     */
    'preferredLanguageCode': string;
    /**
     * The ID that the user\'s identity provider uses.
     * @type {string}
     * @memberof CompanyUserDto
     */
    'identityProviderId': string;
    /**
     * The user is verified or not.
     * @type {boolean}
     * @memberof CompanyUserDto
     */
    'emailVerified': boolean;
    /**
     * 
     * @type {string}
     * @memberof CompanyUserDto
     */
    'accountStatus': CompanyUserDtoAccountStatusEnum;
    /**
     * Datetime when account was created
     * @type {string}
     * @memberof CompanyUserDto
     */
    'createAt': string;
}

export const CompanyUserDtoAccountStatusEnum = {
    Pending: 'pending',
    Active: 'active',
    Declined: 'declined',
    Invited: 'invited'
} as const;

export type CompanyUserDtoAccountStatusEnum = typeof CompanyUserDtoAccountStatusEnum[keyof typeof CompanyUserDtoAccountStatusEnum];

/**
 * 
 * @export
 * @interface CompanyUserWithRolesDto
 */
export interface CompanyUserWithRolesDto {
    /**
     * The user\'s unique identifier.
     * @type {string}
     * @memberof CompanyUserWithRolesDto
     */
    'id': string;
    /**
     * The user\'s email.
     * @type {string}
     * @memberof CompanyUserWithRolesDto
     */
    'email': string;
    /**
     * The user\'s first name.
     * @type {string}
     * @memberof CompanyUserWithRolesDto
     */
    'firstName': string;
    /**
     * The user\'s last name.
     * @type {string}
     * @memberof CompanyUserWithRolesDto
     */
    'lastName': string;
    /**
     * The user\'s phone number.
     * @type {string}
     * @memberof CompanyUserWithRolesDto
     */
    'phone'?: string | null;
    /**
     * The user\'s preferred language code.
     * @type {string}
     * @memberof CompanyUserWithRolesDto
     */
    'preferredLanguageCode': string;
    /**
     * The ID that the user\'s identity provider uses.
     * @type {string}
     * @memberof CompanyUserWithRolesDto
     */
    'identityProviderId': string;
    /**
     * The user is verified or not.
     * @type {boolean}
     * @memberof CompanyUserWithRolesDto
     */
    'emailVerified': boolean;
    /**
     * 
     * @type {string}
     * @memberof CompanyUserWithRolesDto
     */
    'accountStatus': CompanyUserWithRolesDtoAccountStatusEnum;
    /**
     * Datetime when account was created
     * @type {string}
     * @memberof CompanyUserWithRolesDto
     */
    'createAt': string;
    /**
     * 
     * @type {Array<Role>}
     * @memberof CompanyUserWithRolesDto
     */
    'roles': Array<Role>;
}

export const CompanyUserWithRolesDtoAccountStatusEnum = {
    Pending: 'pending',
    Active: 'active',
    Declined: 'declined',
    Invited: 'invited'
} as const;

export type CompanyUserWithRolesDtoAccountStatusEnum = typeof CompanyUserWithRolesDtoAccountStatusEnum[keyof typeof CompanyUserWithRolesDtoAccountStatusEnum];

/**
 * 
 * @export
 * @interface CompanyWithAddressDto
 */
export interface CompanyWithAddressDto {
    /**
     * The company\'s unique identifier.
     * @type {string}
     * @memberof CompanyWithAddressDto
     */
    'id': string;
    /**
     * The company\'s name.
     * @type {string}
     * @memberof CompanyWithAddressDto
     */
    'name': string;
    /**
     * The company\'s business relation id.
     * @type {string}
     * @memberof CompanyWithAddressDto
     */
    'businessRelationId'?: string | null;
    /**
     * The company\'s business relation type.
     * @type {string}
     * @memberof CompanyWithAddressDto
     */
    'businessRelationType'?: CompanyWithAddressDtoBusinessRelationTypeEnum | null;
    /**
     * The company\'s favorite dealer business relation id.
     * @type {string}
     * @memberof CompanyWithAddressDto
     */
    'dealerBusinessRelationId'?: string | null;
    /**
     * 
     * @type {CompanyWithAddressDtoAddress}
     * @memberof CompanyWithAddressDto
     */
    'address'?: CompanyWithAddressDtoAddress | null;
}

export const CompanyWithAddressDtoBusinessRelationTypeEnum = {
    Group: 'group',
    Reseller: 'reseller',
    Customer: 'customer'
} as const;

export type CompanyWithAddressDtoBusinessRelationTypeEnum = typeof CompanyWithAddressDtoBusinessRelationTypeEnum[keyof typeof CompanyWithAddressDtoBusinessRelationTypeEnum];

/**
 * The primary address of the company.
 * @export
 * @interface CompanyWithAddressDtoAddress
 */
export interface CompanyWithAddressDtoAddress {
    /**
     * The company address city.
     * @type {string}
     * @memberof CompanyWithAddressDtoAddress
     */
    'city': string;
    /**
     * The company address country code.
     * @type {string}
     * @memberof CompanyWithAddressDtoAddress
     */
    'countryCode': string;
    /**
     * The company address street.
     * @type {string}
     * @memberof CompanyWithAddressDtoAddress
     */
    'street': string;
    /**
     * The company address zip code.
     * @type {string}
     * @memberof CompanyWithAddressDtoAddress
     */
    'zipCode': string;
}
/**
 * 
 * @export
 * @interface CreateCompanyAddressDto
 */
export interface CreateCompanyAddressDto {
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyAddressDto
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyAddressDto
     */
    'countryCode': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyAddressDto
     */
    'street': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyAddressDto
     */
    'zipCode': string;
}
/**
 * 
 * @export
 * @interface CreateMachineDto
 */
export interface CreateMachineDto {
    /**
     * The serial number of the machine
     * @type {string}
     * @memberof CreateMachineDto
     */
    'serialNumber': string;
    /**
     * The custom name assigned by the user (optional)
     * @type {string}
     * @memberof CreateMachineDto
     */
    'customMachineName': string | null;
}
/**
 * 
 * @export
 * @interface CreateUserFacingCompanyDto
 */
export interface CreateUserFacingCompanyDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUserFacingCompanyDto
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserFacingCompanyDto
     */
    'countryCode': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserFacingCompanyDto
     */
    'street': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserFacingCompanyDto
     */
    'zipCode': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserFacingCompanyDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface GetAllCompanyUsersResponseDto
 */
export interface GetAllCompanyUsersResponseDto {
    /**
     * 
     * @type {Array<CompanyUserDto>}
     * @memberof GetAllCompanyUsersResponseDto
     */
    'data': Array<CompanyUserDto>;
}
/**
 * 
 * @export
 * @interface GetCompanyUserResponseDto
 */
export interface GetCompanyUserResponseDto {
    /**
     * 
     * @type {CompanyUserWithRolesDto}
     * @memberof GetCompanyUserResponseDto
     */
    'data': CompanyUserWithRolesDto;
}
/**
 * 
 * @export
 * @interface GetMachineDto
 */
export interface GetMachineDto {
    /**
     * The serial number of the machine
     * @type {string}
     * @memberof GetMachineDto
     */
    'serialNumber': string;
}
/**
 * 
 * @export
 * @interface InviteEmployeeRequestDto
 */
export interface InviteEmployeeRequestDto {
    /**
     * 
     * @type {string}
     * @memberof InviteEmployeeRequestDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof InviteEmployeeRequestDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof InviteEmployeeRequestDto
     */
    'lastName': string;
}
/**
 * 
 * @export
 * @interface InviteEmployeeResponseDto
 */
export interface InviteEmployeeResponseDto {
    /**
     * 
     * @type {InviteEmployeeResponseDtoData}
     * @memberof InviteEmployeeResponseDto
     */
    'data': InviteEmployeeResponseDtoData;
}
/**
 * 
 * @export
 * @interface InviteEmployeeResponseDtoData
 */
export interface InviteEmployeeResponseDtoData {
    /**
     * 
     * @type {string}
     * @memberof InviteEmployeeResponseDtoData
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof InviteEmployeeResponseDtoData
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof InviteEmployeeResponseDtoData
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof InviteEmployeeResponseDtoData
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof InviteEmployeeResponseDtoData
     */
    'companyId'?: string;
    /**
     * 
     * @type {string}
     * @memberof InviteEmployeeResponseDtoData
     */
    'preferredLanguageCode'?: string;
}
/**
 * 
 * @export
 * @interface ListOwnerMachineSharingContractsResponseDto
 */
export interface ListOwnerMachineSharingContractsResponseDto {
    /**
     * List of created machine sharing contracts
     * @type {Array<MachineSharingContractDto>}
     * @memberof ListOwnerMachineSharingContractsResponseDto
     */
    'machineSharingContracts': Array<MachineSharingContractDto>;
}
/**
 * 
 * @export
 * @interface ListPendingApprovalMachineSharingContractsResponseDto
 */
export interface ListPendingApprovalMachineSharingContractsResponseDto {
    /**
     * List of created machine sharing contracts
     * @type {Array<MachineSharingContractDto>}
     * @memberof ListPendingApprovalMachineSharingContractsResponseDto
     */
    'machineSharingContracts': Array<MachineSharingContractDto>;
}
/**
 * 
 * @export
 * @interface MachineDto
 */
export interface MachineDto {
    /**
     * The serial number of the machine
     * @type {string}
     * @memberof MachineDto
     */
    'serialNumber': string;
    /**
     * The model of the machine
     * @type {string}
     * @memberof MachineDto
     */
    'model': string;
    /**
     * The year of construction of the machine
     * @type {number}
     * @memberof MachineDto
     */
    'yearOfConstruction': number;
    /**
     * Whether the machine has a gsc box or not
     * @type {boolean}
     * @memberof MachineDto
     */
    'hasGsc': boolean;
    /**
     * The custom name assigned by the user (optional)
     * @type {string}
     * @memberof MachineDto
     */
    'customMachineName': string | null;
    /**
     * The current status of the machine
     * @type {string}
     * @memberof MachineDto
     */
    'status': MachineDtoStatusEnum;
    /**
     * The date when the machine became active
     * @type {string}
     * @memberof MachineDto
     */
    'startedAt': string | null;
    /**
     * The date when the machine was removed from the account or declined
     * @type {string}
     * @memberof MachineDto
     */
    'endedAt': string | null;
    /**
     * The date when the machine was created
     * @type {string}
     * @memberof MachineDto
     */
    'createdAt': string | null;
}

export const MachineDtoStatusEnum = {
    Pending: 'pending',
    Active: 'active',
    Inactive: 'inactive',
    Declined: 'declined'
} as const;

export type MachineDtoStatusEnum = typeof MachineDtoStatusEnum[keyof typeof MachineDtoStatusEnum];

/**
 * 
 * @export
 * @interface MachineOwnershipCompanyDto
 */
export interface MachineOwnershipCompanyDto {
    /**
     * The company\'s unique identifier.
     * @type {string}
     * @memberof MachineOwnershipCompanyDto
     */
    'id': string;
    /**
     * The company\'s name.
     * @type {string}
     * @memberof MachineOwnershipCompanyDto
     */
    'name': string;
    /**
     * The company\'s business relation id.
     * @type {string}
     * @memberof MachineOwnershipCompanyDto
     */
    'businessRelationId'?: string;
    /**
     * The company\'s business relation type
     * @type {string}
     * @memberof MachineOwnershipCompanyDto
     */
    'businessRelationType': MachineOwnershipCompanyDtoBusinessRelationTypeEnum;
}

export const MachineOwnershipCompanyDtoBusinessRelationTypeEnum = {
    Group: 'group',
    Reseller: 'reseller',
    Customer: 'customer'
} as const;

export type MachineOwnershipCompanyDtoBusinessRelationTypeEnum = typeof MachineOwnershipCompanyDtoBusinessRelationTypeEnum[keyof typeof MachineOwnershipCompanyDtoBusinessRelationTypeEnum];

/**
 * 
 * @export
 * @interface MachineOwnershipDto
 */
export interface MachineOwnershipDto {
    /**
     * Company associated with machine ownership
     * @type {MachineOwnershipCompanyDto}
     * @memberof MachineOwnershipDto
     */
    'company': MachineOwnershipCompanyDto;
    /**
     * Ownership start date
     * @type {string}
     * @memberof MachineOwnershipDto
     */
    'startedAt': string;
    /**
     * Ownership end date
     * @type {string}
     * @memberof MachineOwnershipDto
     */
    'endedAt': string | null;
    /**
     * Ownership status
     * @type {string}
     * @memberof MachineOwnershipDto
     */
    'status': MachineOwnershipDtoStatusEnum;
}

export const MachineOwnershipDtoStatusEnum = {
    Pending: 'pending',
    Active: 'active',
    Inactive: 'inactive',
    Declined: 'declined'
} as const;

export type MachineOwnershipDtoStatusEnum = typeof MachineOwnershipDtoStatusEnum[keyof typeof MachineOwnershipDtoStatusEnum];

/**
 * 
 * @export
 * @interface MachineSharingContract
 */
export interface MachineSharingContract {
    /**
     * Id of the machine sharing contract
     * @type {string}
     * @memberof MachineSharingContract
     */
    'id': string;
    /**
     * Machine owner company details
     * @type {OwnerCompany}
     * @memberof MachineSharingContract
     */
    'ownerCompany': OwnerCompany;
}
/**
 * 
 * @export
 * @interface MachineSharingContractDto
 */
export interface MachineSharingContractDto {
    /**
     * The serial number of the machine
     * @type {string}
     * @memberof MachineSharingContractDto
     */
    'id': string;
    /**
     * Machine sharing contract status
     * @type {string}
     * @memberof MachineSharingContractDto
     */
    'status': MachineSharingContractDtoStatusEnum;
    /**
     * Party that initiated the sharing contract
     * @type {string}
     * @memberof MachineSharingContractDto
     */
    'initiatedBy': MachineSharingContractDtoInitiatedByEnum;
    /**
     * \"True\" if owner accepted machine sharing. When owner initiates sharing contract - consent is automatically set to \"True\"
     * @type {boolean}
     * @memberof MachineSharingContractDto
     */
    'ownerConsent': boolean;
    /**
     * \"True\" if recipient accepted machine sharing. When recipient initiates sharing contract - consent is automatically set to \"True\"
     * @type {boolean}
     * @memberof MachineSharingContractDto
     */
    'recipientConsent': boolean;
    /**
     * 
     * @type {MachineSharingContractMachineDto}
     * @memberof MachineSharingContractDto
     */
    'machine': MachineSharingContractMachineDto;
    /**
     * 
     * @type {MachineSharingContractPartyDto}
     * @memberof MachineSharingContractDto
     */
    'ownerCompany': MachineSharingContractPartyDto;
    /**
     * 
     * @type {MachineSharingContractPartyDto}
     * @memberof MachineSharingContractDto
     */
    'recipientCompany': MachineSharingContractPartyDto;
    /**
     * 
     * @type {string}
     * @memberof MachineSharingContractDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof MachineSharingContractDto
     */
    'updatedAt': string;
}

export const MachineSharingContractDtoStatusEnum = {
    Pending: 'pending',
    Active: 'active'
} as const;

export type MachineSharingContractDtoStatusEnum = typeof MachineSharingContractDtoStatusEnum[keyof typeof MachineSharingContractDtoStatusEnum];
export const MachineSharingContractDtoInitiatedByEnum = {
    Owner: 'owner',
    Recipient: 'recipient'
} as const;

export type MachineSharingContractDtoInitiatedByEnum = typeof MachineSharingContractDtoInitiatedByEnum[keyof typeof MachineSharingContractDtoInitiatedByEnum];

/**
 * 
 * @export
 * @interface MachineSharingContractMachineDto
 */
export interface MachineSharingContractMachineDto {
    /**
     * The serial number of the machine
     * @type {string}
     * @memberof MachineSharingContractMachineDto
     */
    'serialNumber': string;
    /**
     * The model of the machine
     * @type {string}
     * @memberof MachineSharingContractMachineDto
     */
    'model': string;
    /**
     * The year of construction of the machine
     * @type {number}
     * @memberof MachineSharingContractMachineDto
     */
    'yearOfConstruction': number;
    /**
     * The custom name assigned by the user (optional)
     * @type {string}
     * @memberof MachineSharingContractMachineDto
     */
    'customMachineName': string | null;
}
/**
 * 
 * @export
 * @interface MachineSharingContractPartyDto
 */
export interface MachineSharingContractPartyDto {
    /**
     * Company id of the machine sharing party
     * @type {string}
     * @memberof MachineSharingContractPartyDto
     */
    'id': string;
    /**
     * Company name of the machine sharing party
     * @type {string}
     * @memberof MachineSharingContractPartyDto
     */
    'name': string;
    /**
     * Company business relation id
     * @type {string}
     * @memberof MachineSharingContractPartyDto
     */
    'businessRelationId': string;
}
/**
 * 
 * @export
 * @interface MachineWithOwnershipsGetResponseDto
 */
export interface MachineWithOwnershipsGetResponseDto {
    /**
     * 
     * @type {MachineDto}
     * @memberof MachineWithOwnershipsGetResponseDto
     */
    'machine': MachineDto;
    /**
     * List of machine ownerships
     * @type {Array<MachineOwnershipDto>}
     * @memberof MachineWithOwnershipsGetResponseDto
     */
    'ownerships': Array<MachineOwnershipDto>;
}
/**
 * 
 * @export
 * @interface OfferMachineSharingContractRequestDto
 */
export interface OfferMachineSharingContractRequestDto {
    /**
     * Serial numbers of the machines to share
     * @type {Array<string>}
     * @memberof OfferMachineSharingContractRequestDto
     */
    'machinesSerialNumbers': Array<string>;
    /**
     * BR of recipient company. Either recipientBR or recipientEmail has to be passed to find the company.
     * @type {string}
     * @memberof OfferMachineSharingContractRequestDto
     */
    'recipientBR'?: string | null;
    /**
     * Email of the recipient company representative. Either recipientBR or recipientEmail has to be passed to find the company.
     * @type {string}
     * @memberof OfferMachineSharingContractRequestDto
     */
    'recipientEmail'?: string | null;
}
/**
 * 
 * @export
 * @interface OfferMachineSharingContractResponseDto
 */
export interface OfferMachineSharingContractResponseDto {
    /**
     * List of created machine sharing contracts
     * @type {Array<MachineSharingContractDto>}
     * @memberof OfferMachineSharingContractResponseDto
     */
    'machineSharingContracts': Array<MachineSharingContractDto>;
}
/**
 * 
 * @export
 * @interface OwnerCompany
 */
export interface OwnerCompany {
    /**
     * Name of the owner company
     * @type {string}
     * @memberof OwnerCompany
     */
    'name': string;
    /**
     * BR Id of the owner company
     * @type {string}
     * @memberof OwnerCompany
     */
    'businessRelationId': string | null;
}
/**
 * 
 * @export
 * @interface PatchUserDto
 */
export interface PatchUserDto {
    /**
     * Optional first name of the user to update
     * @type {string}
     * @memberof PatchUserDto
     */
    'firstName'?: string;
    /**
     * Optional last name of the user to update
     * @type {string}
     * @memberof PatchUserDto
     */
    'lastName'?: string;
    /**
     * Optional phone number of the user to update
     * @type {string}
     * @memberof PatchUserDto
     */
    'phone'?: string | null;
    /**
     * Optional preferred language code of the user to update
     * @type {string}
     * @memberof PatchUserDto
     */
    'preferredLanguageCode'?: string;
}
/**
 * 
 * @export
 * @interface PatchUserFacingCompanyDto
 */
export interface PatchUserFacingCompanyDto {
    /**
     * 
     * @type {string}
     * @memberof PatchUserFacingCompanyDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchUserFacingCompanyDto
     */
    'dealerBusinessRelationId'?: string;
    /**
     * 
     * @type {CreateCompanyAddressDto}
     * @memberof PatchUserFacingCompanyDto
     */
    'address'?: CreateCompanyAddressDto;
}
/**
 * 
 * @export
 * @interface PatchUserPrimaryAddressBody
 */
export interface PatchUserPrimaryAddressBody {
    /**
     * 
     * @type {string}
     * @memberof PatchUserPrimaryAddressBody
     */
    'street': string;
    /**
     * 
     * @type {string}
     * @memberof PatchUserPrimaryAddressBody
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof PatchUserPrimaryAddressBody
     */
    'zipCode': string;
    /**
     * 
     * @type {string}
     * @memberof PatchUserPrimaryAddressBody
     */
    'countryCode': string;
}
/**
 * 
 * @export
 * @interface Permission
 */
export interface Permission {
    /**
     * 
     * @type {string}
     * @memberof Permission
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof Permission
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ProbeOfferMachineSharingContractResponseDto
 */
export interface ProbeOfferMachineSharingContractResponseDto {
    /**
     * Flag showing whether machine sharing can be offered to specified counter party
     * @type {boolean}
     * @memberof ProbeOfferMachineSharingContractResponseDto
     */
    'canOfferSharing': boolean;
    /**
     * Reason explaining details of canOfferSharing value. Reason is \"null\" when \"canOfferSharing\" is \"true\".
     * @type {string}
     * @memberof ProbeOfferMachineSharingContractResponseDto
     */
    'reason': string | null;
}
/**
 * 
 * @export
 * @interface RequestMachineSharingContractRequestDto
 */
export interface RequestMachineSharingContractRequestDto {
    /**
     * Serial number of the machine to request sharing for
     * @type {string}
     * @memberof RequestMachineSharingContractRequestDto
     */
    'machineSerialNumber': string;
    /**
     * The custom name assigned to machine by the recipient
     * @type {string}
     * @memberof RequestMachineSharingContractRequestDto
     */
    'customMachineName'?: string;
}
/**
 * 
 * @export
 * @interface RequestMachineSharingContractResponseDto
 */
export interface RequestMachineSharingContractResponseDto {
    /**
     * The serial number of the machine
     * @type {string}
     * @memberof RequestMachineSharingContractResponseDto
     */
    'id': string;
    /**
     * Machine sharing contract status
     * @type {string}
     * @memberof RequestMachineSharingContractResponseDto
     */
    'status': RequestMachineSharingContractResponseDtoStatusEnum;
    /**
     * Party that initiated the sharing contract
     * @type {string}
     * @memberof RequestMachineSharingContractResponseDto
     */
    'initiatedBy': RequestMachineSharingContractResponseDtoInitiatedByEnum;
    /**
     * \"True\" if owner accepted machine sharing. When owner initiates sharing contract - consent is automatically set to \"True\"
     * @type {boolean}
     * @memberof RequestMachineSharingContractResponseDto
     */
    'ownerConsent': boolean;
    /**
     * \"True\" if recipient accepted machine sharing. When recipient initiates sharing contract - consent is automatically set to \"True\"
     * @type {boolean}
     * @memberof RequestMachineSharingContractResponseDto
     */
    'recipientConsent': boolean;
    /**
     * 
     * @type {MachineSharingContractMachineDto}
     * @memberof RequestMachineSharingContractResponseDto
     */
    'machine': MachineSharingContractMachineDto;
    /**
     * 
     * @type {MachineSharingContractPartyDto}
     * @memberof RequestMachineSharingContractResponseDto
     */
    'ownerCompany': MachineSharingContractPartyDto;
    /**
     * 
     * @type {MachineSharingContractPartyDto}
     * @memberof RequestMachineSharingContractResponseDto
     */
    'recipientCompany': MachineSharingContractPartyDto;
    /**
     * 
     * @type {string}
     * @memberof RequestMachineSharingContractResponseDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof RequestMachineSharingContractResponseDto
     */
    'updatedAt': string;
}

export const RequestMachineSharingContractResponseDtoStatusEnum = {
    Pending: 'pending',
    Active: 'active'
} as const;

export type RequestMachineSharingContractResponseDtoStatusEnum = typeof RequestMachineSharingContractResponseDtoStatusEnum[keyof typeof RequestMachineSharingContractResponseDtoStatusEnum];
export const RequestMachineSharingContractResponseDtoInitiatedByEnum = {
    Owner: 'owner',
    Recipient: 'recipient'
} as const;

export type RequestMachineSharingContractResponseDtoInitiatedByEnum = typeof RequestMachineSharingContractResponseDtoInitiatedByEnum[keyof typeof RequestMachineSharingContractResponseDtoInitiatedByEnum];

/**
 * 
 * @export
 * @interface Role
 */
export interface Role {
    /**
     * The role\'s unique identifier.
     * @type {string}
     * @memberof Role
     */
    'id': string;
    /**
     * The role\'s unique identifier.
     * @type {string}
     * @memberof Role
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface SharedMachineDto
 */
export interface SharedMachineDto {
    /**
     * The serial number of the machine
     * @type {string}
     * @memberof SharedMachineDto
     */
    'serialNumber': string;
    /**
     * The model of the machine
     * @type {string}
     * @memberof SharedMachineDto
     */
    'model': string;
    /**
     * The year of construction of the machine
     * @type {number}
     * @memberof SharedMachineDto
     */
    'yearOfConstruction': number;
    /**
     * Whether the machine has a gsc box or not
     * @type {boolean}
     * @memberof SharedMachineDto
     */
    'hasGsc': boolean;
    /**
     * The custom name assigned by the user (optional)
     * @type {string}
     * @memberof SharedMachineDto
     */
    'customMachineName': string | null;
    /**
     * The current status of the machine
     * @type {string}
     * @memberof SharedMachineDto
     */
    'status': SharedMachineDtoStatusEnum;
    /**
     * The date when the machine became active
     * @type {string}
     * @memberof SharedMachineDto
     */
    'startedAt': string | null;
    /**
     * The date when the machine was removed from the account or declined
     * @type {string}
     * @memberof SharedMachineDto
     */
    'endedAt': string | null;
    /**
     * The date when the machine was created
     * @type {string}
     * @memberof SharedMachineDto
     */
    'createdAt': string | null;
    /**
     * Machine sharing contract by which the machine is provided to current company
     * @type {MachineSharingContract}
     * @memberof SharedMachineDto
     */
    'machineSharingContract': MachineSharingContract;
}

export const SharedMachineDtoStatusEnum = {
    Pending: 'pending',
    Active: 'active',
    Inactive: 'inactive',
    Declined: 'declined'
} as const;

export type SharedMachineDtoStatusEnum = typeof SharedMachineDtoStatusEnum[keyof typeof SharedMachineDtoStatusEnum];

/**
 * 
 * @export
 * @interface UpdateCompanyUserRequestDto
 */
export interface UpdateCompanyUserRequestDto {
    /**
     * Role IDs that need to override the previous ones
     * @type {Array<Role>}
     * @memberof UpdateCompanyUserRequestDto
     */
    'assignedRoles': Array<Role>;
}
/**
 * 
 * @export
 * @interface UpdateMachineDto
 */
export interface UpdateMachineDto {
    /**
     * The custom name assigned by the user (optional)
     * @type {string}
     * @memberof UpdateMachineDto
     */
    'customMachineName': string | null;
}
/**
 * 
 * @export
 * @interface UpdateMachineSharedRequestDto
 */
export interface UpdateMachineSharedRequestDto {
    /**
     * The custom name assigned by the recipient
     * @type {string}
     * @memberof UpdateMachineSharedRequestDto
     */
    'customMachineName': string;
}
/**
 * 
 * @export
 * @interface UpdateMachineSharedResponseDto
 */
export interface UpdateMachineSharedResponseDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateMachineSharedResponseDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMachineSharedResponseDto
     */
    'customMachineName': string;
}
/**
 * 
 * @export
 * @interface UserDto
 */
export interface UserDto {
    /**
     * The user\'s unique identifier.
     * @type {string}
     * @memberof UserDto
     */
    'id': string;
    /**
     * The user\'s email.
     * @type {string}
     * @memberof UserDto
     */
    'email': string;
    /**
     * The user\'s first name.
     * @type {string}
     * @memberof UserDto
     */
    'firstName': string;
    /**
     * The user\'s last name.
     * @type {string}
     * @memberof UserDto
     */
    'lastName': string;
    /**
     * The user\'s phone number.
     * @type {string}
     * @memberof UserDto
     */
    'phone'?: string | null;
    /**
     * The user\'s preferred language code.
     * @type {string}
     * @memberof UserDto
     */
    'preferredLanguageCode': string;
    /**
     * The ID that the user\'s identity provider uses.
     * @type {string}
     * @memberof UserDto
     */
    'identityProviderId': string;
    /**
     * The ID of the company the user is associated with.
     * @type {string}
     * @memberof UserDto
     */
    'companyId'?: string | null;
    /**
     * The ID of the company contact the user is associated with.
     * @type {string}
     * @memberof UserDto
     */
    'companyContactId'?: string | null;
    /**
     * The user is verified or not.
     * @type {boolean}
     * @memberof UserDto
     */
    'emailVerified': boolean;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'accountStatus': UserDtoAccountStatusEnum;
    /**
     * Datetime when account was created
     * @type {string}
     * @memberof UserDto
     */
    'createAt': string;
    /**
     * 
     * @type {UserDtoCompany}
     * @memberof UserDto
     */
    'company'?: UserDtoCompany | null;
}

export const UserDtoAccountStatusEnum = {
    Pending: 'pending',
    Active: 'active',
    Declined: 'declined',
    Invited: 'invited'
} as const;

export type UserDtoAccountStatusEnum = typeof UserDtoAccountStatusEnum[keyof typeof UserDtoAccountStatusEnum];

/**
 * The company the user is associated with.
 * @export
 * @interface UserDtoCompany
 */
export interface UserDtoCompany {
    /**
     * The company\'s unique identifier.
     * @type {string}
     * @memberof UserDtoCompany
     */
    'id': string;
    /**
     * The company\'s name.
     * @type {string}
     * @memberof UserDtoCompany
     */
    'name': string;
    /**
     * The company\'s business relation id.
     * @type {string}
     * @memberof UserDtoCompany
     */
    'businessRelationId'?: string | null;
    /**
     * The company\'s business relation type.
     * @type {string}
     * @memberof UserDtoCompany
     */
    'businessRelationType'?: UserDtoCompanyBusinessRelationTypeEnum | null;
    /**
     * The company\'s favorite dealer business relation id.
     * @type {string}
     * @memberof UserDtoCompany
     */
    'dealerBusinessRelationId'?: string | null;
    /**
     * 
     * @type {CompanyWithAddressDtoAddress}
     * @memberof UserDtoCompany
     */
    'address'?: CompanyWithAddressDtoAddress | null;
}

export const UserDtoCompanyBusinessRelationTypeEnum = {
    Group: 'group',
    Reseller: 'reseller',
    Customer: 'customer'
} as const;

export type UserDtoCompanyBusinessRelationTypeEnum = typeof UserDtoCompanyBusinessRelationTypeEnum[keyof typeof UserDtoCompanyBusinessRelationTypeEnum];

/**
 * 
 * @export
 * @interface UserFacingCompanyAddressDto
 */
export interface UserFacingCompanyAddressDto {
    /**
     * 
     * @type {string}
     * @memberof UserFacingCompanyAddressDto
     */
    'id': string;
    /**
     * The company address city.
     * @type {string}
     * @memberof UserFacingCompanyAddressDto
     */
    'city': string;
    /**
     * The company address country code.
     * @type {string}
     * @memberof UserFacingCompanyAddressDto
     */
    'countryCode': string;
    /**
     * The company address street.
     * @type {string}
     * @memberof UserFacingCompanyAddressDto
     */
    'street': string;
    /**
     * The company address zip code.
     * @type {string}
     * @memberof UserFacingCompanyAddressDto
     */
    'zipCode': string;
    /**
     * Whether the address is primary.
     * @type {boolean}
     * @memberof UserFacingCompanyAddressDto
     */
    'isPrimary': boolean;
}
/**
 * 
 * @export
 * @interface UserFacingCompanyDto
 */
export interface UserFacingCompanyDto {
    /**
     * The company\'s unique identifier.
     * @type {string}
     * @memberof UserFacingCompanyDto
     */
    'id': string;
    /**
     * The company\'s name.
     * @type {string}
     * @memberof UserFacingCompanyDto
     */
    'name': string;
    /**
     * The company\'s business relation id.
     * @type {string}
     * @memberof UserFacingCompanyDto
     */
    'businessRelationId'?: string | null;
    /**
     * The company\'s business relation type.
     * @type {string}
     * @memberof UserFacingCompanyDto
     */
    'businessRelationType'?: UserFacingCompanyDtoBusinessRelationTypeEnum | null;
    /**
     * The company\'s favorite dealer business relation id.
     * @type {string}
     * @memberof UserFacingCompanyDto
     */
    'dealerBusinessRelationId'?: string | null;
    /**
     * The addresses of the company.
     * @type {Array<UserFacingCompanyAddressDto>}
     * @memberof UserFacingCompanyDto
     */
    'addresses'?: Array<UserFacingCompanyAddressDto>;
}

export const UserFacingCompanyDtoBusinessRelationTypeEnum = {
    Group: 'group',
    Reseller: 'reseller',
    Customer: 'customer'
} as const;

export type UserFacingCompanyDtoBusinessRelationTypeEnum = typeof UserFacingCompanyDtoBusinessRelationTypeEnum[keyof typeof UserFacingCompanyDtoBusinessRelationTypeEnum];

/**
 * 
 * @export
 * @interface UserPermissionsResponseDto
 */
export interface UserPermissionsResponseDto {
    /**
     * 
     * @type {Array<Permission>}
     * @memberof UserPermissionsResponseDto
     */
    'data': Array<Permission>;
}
/**
 * 
 * @export
 * @interface UserRolesResponseDto
 */
export interface UserRolesResponseDto {
    /**
     * 
     * @type {Array<Role>}
     * @memberof UserRolesResponseDto
     */
    'data': Array<Role>;
}

/**
 * CurrentUserApi - axios parameter creator
 * @export
 */
export const CurrentUserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserControllerDelete: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/user/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserControllerGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/user/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserControllerGetPermissions: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/user/me/permissions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserControllerGetRoles: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/user/me/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PatchUserDto} patchUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserControllerPatch: async (patchUserDto: PatchUserDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patchUserDto' is not null or undefined
            assertParamExists('currentUserControllerPatch', 'patchUserDto', patchUserDto)
            const localVarPath = `/api/v1/user/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchUserDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CurrentUserApi - functional programming interface
 * @export
 */
export const CurrentUserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CurrentUserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentUserControllerDelete(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentUserControllerDelete(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CurrentUserApi.currentUserControllerDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentUserControllerGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentUserControllerGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CurrentUserApi.currentUserControllerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentUserControllerGetPermissions(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserPermissionsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentUserControllerGetPermissions(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CurrentUserApi.currentUserControllerGetPermissions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentUserControllerGetRoles(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserRolesResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentUserControllerGetRoles(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CurrentUserApi.currentUserControllerGetRoles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {PatchUserDto} patchUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentUserControllerPatch(patchUserDto: PatchUserDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentUserControllerPatch(patchUserDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CurrentUserApi.currentUserControllerPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CurrentUserApi - factory interface
 * @export
 */
export const CurrentUserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CurrentUserApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserControllerDelete(options?: any): AxiosPromise<void> {
            return localVarFp.currentUserControllerDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserControllerGet(options?: any): AxiosPromise<UserDto> {
            return localVarFp.currentUserControllerGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserControllerGetPermissions(options?: any): AxiosPromise<UserPermissionsResponseDto> {
            return localVarFp.currentUserControllerGetPermissions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserControllerGetRoles(options?: any): AxiosPromise<UserRolesResponseDto> {
            return localVarFp.currentUserControllerGetRoles(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PatchUserDto} patchUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserControllerPatch(patchUserDto: PatchUserDto, options?: any): AxiosPromise<UserDto> {
            return localVarFp.currentUserControllerPatch(patchUserDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CurrentUserApi - object-oriented interface
 * @export
 * @class CurrentUserApi
 * @extends {BaseAPI}
 */
export class CurrentUserApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrentUserApi
     */
    public currentUserControllerDelete(options?: RawAxiosRequestConfig) {
        return CurrentUserApiFp(this.configuration).currentUserControllerDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrentUserApi
     */
    public currentUserControllerGet(options?: RawAxiosRequestConfig) {
        return CurrentUserApiFp(this.configuration).currentUserControllerGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrentUserApi
     */
    public currentUserControllerGetPermissions(options?: RawAxiosRequestConfig) {
        return CurrentUserApiFp(this.configuration).currentUserControllerGetPermissions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrentUserApi
     */
    public currentUserControllerGetRoles(options?: RawAxiosRequestConfig) {
        return CurrentUserApiFp(this.configuration).currentUserControllerGetRoles(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PatchUserDto} patchUserDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrentUserApi
     */
    public currentUserControllerPatch(patchUserDto: PatchUserDto, options?: RawAxiosRequestConfig) {
        return CurrentUserApiFp(this.configuration).currentUserControllerPatch(patchUserDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CurrentUserCompanyApi - axios parameter creator
 * @export
 */
export const CurrentUserCompanyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * creates a new company for user and give it first company user role
         * @summary 
         * @param {CreateUserFacingCompanyDto} createUserFacingCompanyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserCompanyControllerCreateCompany: async (createUserFacingCompanyDto: CreateUserFacingCompanyDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createUserFacingCompanyDto' is not null or undefined
            assertParamExists('currentUserCompanyControllerCreateCompany', 'createUserFacingCompanyDto', createUserFacingCompanyDto)
            const localVarPath = `/api/v1/user/me/company`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUserFacingCompanyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserCompanyControllerGetCompany: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/user/me/company`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of roles you can assign to the employees
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserCompanyControllerGetRoles: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/user/me/company/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Patch the user company address.
         * @summary 
         * @param {string} id 
         * @param {PatchUserPrimaryAddressBody} patchUserPrimaryAddressBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserCompanyControllerPatchAddress: async (id: string, patchUserPrimaryAddressBody: PatchUserPrimaryAddressBody, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('currentUserCompanyControllerPatchAddress', 'id', id)
            // verify required parameter 'patchUserPrimaryAddressBody' is not null or undefined
            assertParamExists('currentUserCompanyControllerPatchAddress', 'patchUserPrimaryAddressBody', patchUserPrimaryAddressBody)
            const localVarPath = `/api/v1/user/me/company/addresses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchUserPrimaryAddressBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates company name, primary address and/or dealer id
         * @summary 
         * @param {PatchUserFacingCompanyDto} patchUserFacingCompanyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserCompanyControllerPatchCompany: async (patchUserFacingCompanyDto: PatchUserFacingCompanyDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patchUserFacingCompanyDto' is not null or undefined
            assertParamExists('currentUserCompanyControllerPatchCompany', 'patchUserFacingCompanyDto', patchUserFacingCompanyDto)
            const localVarPath = `/api/v1/user/me/company`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchUserFacingCompanyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CurrentUserCompanyApi - functional programming interface
 * @export
 */
export const CurrentUserCompanyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CurrentUserCompanyApiAxiosParamCreator(configuration)
    return {
        /**
         * creates a new company for user and give it first company user role
         * @summary 
         * @param {CreateUserFacingCompanyDto} createUserFacingCompanyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentUserCompanyControllerCreateCompany(createUserFacingCompanyDto: CreateUserFacingCompanyDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserFacingCompanyDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentUserCompanyControllerCreateCompany(createUserFacingCompanyDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CurrentUserCompanyApi.currentUserCompanyControllerCreateCompany']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentUserCompanyControllerGetCompany(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserFacingCompanyDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentUserCompanyControllerGetCompany(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CurrentUserCompanyApi.currentUserCompanyControllerGetCompany']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List of roles you can assign to the employees
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentUserCompanyControllerGetRoles(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyRolesResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentUserCompanyControllerGetRoles(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CurrentUserCompanyApi.currentUserCompanyControllerGetRoles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Patch the user company address.
         * @summary 
         * @param {string} id 
         * @param {PatchUserPrimaryAddressBody} patchUserPrimaryAddressBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentUserCompanyControllerPatchAddress(id: string, patchUserPrimaryAddressBody: PatchUserPrimaryAddressBody, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentUserCompanyControllerPatchAddress(id, patchUserPrimaryAddressBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CurrentUserCompanyApi.currentUserCompanyControllerPatchAddress']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates company name, primary address and/or dealer id
         * @summary 
         * @param {PatchUserFacingCompanyDto} patchUserFacingCompanyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentUserCompanyControllerPatchCompany(patchUserFacingCompanyDto: PatchUserFacingCompanyDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentUserCompanyControllerPatchCompany(patchUserFacingCompanyDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CurrentUserCompanyApi.currentUserCompanyControllerPatchCompany']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CurrentUserCompanyApi - factory interface
 * @export
 */
export const CurrentUserCompanyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CurrentUserCompanyApiFp(configuration)
    return {
        /**
         * creates a new company for user and give it first company user role
         * @summary 
         * @param {CreateUserFacingCompanyDto} createUserFacingCompanyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserCompanyControllerCreateCompany(createUserFacingCompanyDto: CreateUserFacingCompanyDto, options?: any): AxiosPromise<UserFacingCompanyDto> {
            return localVarFp.currentUserCompanyControllerCreateCompany(createUserFacingCompanyDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserCompanyControllerGetCompany(options?: any): AxiosPromise<UserFacingCompanyDto> {
            return localVarFp.currentUserCompanyControllerGetCompany(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of roles you can assign to the employees
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserCompanyControllerGetRoles(options?: any): AxiosPromise<CompanyRolesResponseDto> {
            return localVarFp.currentUserCompanyControllerGetRoles(options).then((request) => request(axios, basePath));
        },
        /**
         * Patch the user company address.
         * @summary 
         * @param {string} id 
         * @param {PatchUserPrimaryAddressBody} patchUserPrimaryAddressBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserCompanyControllerPatchAddress(id: string, patchUserPrimaryAddressBody: PatchUserPrimaryAddressBody, options?: any): AxiosPromise<void> {
            return localVarFp.currentUserCompanyControllerPatchAddress(id, patchUserPrimaryAddressBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates company name, primary address and/or dealer id
         * @summary 
         * @param {PatchUserFacingCompanyDto} patchUserFacingCompanyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserCompanyControllerPatchCompany(patchUserFacingCompanyDto: PatchUserFacingCompanyDto, options?: any): AxiosPromise<void> {
            return localVarFp.currentUserCompanyControllerPatchCompany(patchUserFacingCompanyDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CurrentUserCompanyApi - object-oriented interface
 * @export
 * @class CurrentUserCompanyApi
 * @extends {BaseAPI}
 */
export class CurrentUserCompanyApi extends BaseAPI {
    /**
     * creates a new company for user and give it first company user role
     * @summary 
     * @param {CreateUserFacingCompanyDto} createUserFacingCompanyDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrentUserCompanyApi
     */
    public currentUserCompanyControllerCreateCompany(createUserFacingCompanyDto: CreateUserFacingCompanyDto, options?: RawAxiosRequestConfig) {
        return CurrentUserCompanyApiFp(this.configuration).currentUserCompanyControllerCreateCompany(createUserFacingCompanyDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrentUserCompanyApi
     */
    public currentUserCompanyControllerGetCompany(options?: RawAxiosRequestConfig) {
        return CurrentUserCompanyApiFp(this.configuration).currentUserCompanyControllerGetCompany(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of roles you can assign to the employees
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrentUserCompanyApi
     */
    public currentUserCompanyControllerGetRoles(options?: RawAxiosRequestConfig) {
        return CurrentUserCompanyApiFp(this.configuration).currentUserCompanyControllerGetRoles(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Patch the user company address.
     * @summary 
     * @param {string} id 
     * @param {PatchUserPrimaryAddressBody} patchUserPrimaryAddressBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrentUserCompanyApi
     */
    public currentUserCompanyControllerPatchAddress(id: string, patchUserPrimaryAddressBody: PatchUserPrimaryAddressBody, options?: RawAxiosRequestConfig) {
        return CurrentUserCompanyApiFp(this.configuration).currentUserCompanyControllerPatchAddress(id, patchUserPrimaryAddressBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates company name, primary address and/or dealer id
     * @summary 
     * @param {PatchUserFacingCompanyDto} patchUserFacingCompanyDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrentUserCompanyApi
     */
    public currentUserCompanyControllerPatchCompany(patchUserFacingCompanyDto: PatchUserFacingCompanyDto, options?: RawAxiosRequestConfig) {
        return CurrentUserCompanyApiFp(this.configuration).currentUserCompanyControllerPatchCompany(patchUserFacingCompanyDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CurrentUserCompanyUsersApi - axios parameter creator
 * @export
 */
export const CurrentUserCompanyUsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete employee account and send them a confirmation email
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserCompanyUsersControllerDeleteEmployeeAccount: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('currentUserCompanyUsersControllerDeleteEmployeeAccount', 'id', id)
            const localVarPath = `/api/v1/user/me/company/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary All employees in the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserCompanyUsersControllerFindAllUsers: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/user/me/company/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch employee account with roles
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserCompanyUsersControllerGetOne: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('currentUserCompanyUsersControllerGetOne', 'id', id)
            const localVarPath = `/api/v1/user/me/company/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates unverified account and send them invitation email
         * @param {InviteEmployeeRequestDto} inviteEmployeeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserCompanyUsersControllerInviteEmployee: async (inviteEmployeeRequestDto: InviteEmployeeRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inviteEmployeeRequestDto' is not null or undefined
            assertParamExists('currentUserCompanyUsersControllerInviteEmployee', 'inviteEmployeeRequestDto', inviteEmployeeRequestDto)
            const localVarPath = `/api/v1/user/me/company/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inviteEmployeeRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch employee account with roles
         * @param {string} id 
         * @param {UpdateCompanyUserRequestDto} updateCompanyUserRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserCompanyUsersControllerUpdateOne: async (id: string, updateCompanyUserRequestDto: UpdateCompanyUserRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('currentUserCompanyUsersControllerUpdateOne', 'id', id)
            // verify required parameter 'updateCompanyUserRequestDto' is not null or undefined
            assertParamExists('currentUserCompanyUsersControllerUpdateOne', 'updateCompanyUserRequestDto', updateCompanyUserRequestDto)
            const localVarPath = `/api/v1/user/me/company/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCompanyUserRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CurrentUserCompanyUsersApi - functional programming interface
 * @export
 */
export const CurrentUserCompanyUsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CurrentUserCompanyUsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete employee account and send them a confirmation email
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentUserCompanyUsersControllerDeleteEmployeeAccount(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InviteEmployeeResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentUserCompanyUsersControllerDeleteEmployeeAccount(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CurrentUserCompanyUsersApi.currentUserCompanyUsersControllerDeleteEmployeeAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary All employees in the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentUserCompanyUsersControllerFindAllUsers(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAllCompanyUsersResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentUserCompanyUsersControllerFindAllUsers(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CurrentUserCompanyUsersApi.currentUserCompanyUsersControllerFindAllUsers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Fetch employee account with roles
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentUserCompanyUsersControllerGetOne(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCompanyUserResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentUserCompanyUsersControllerGetOne(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CurrentUserCompanyUsersApi.currentUserCompanyUsersControllerGetOne']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Creates unverified account and send them invitation email
         * @param {InviteEmployeeRequestDto} inviteEmployeeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentUserCompanyUsersControllerInviteEmployee(inviteEmployeeRequestDto: InviteEmployeeRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InviteEmployeeResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentUserCompanyUsersControllerInviteEmployee(inviteEmployeeRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CurrentUserCompanyUsersApi.currentUserCompanyUsersControllerInviteEmployee']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Fetch employee account with roles
         * @param {string} id 
         * @param {UpdateCompanyUserRequestDto} updateCompanyUserRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentUserCompanyUsersControllerUpdateOne(id: string, updateCompanyUserRequestDto: UpdateCompanyUserRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCompanyUserResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentUserCompanyUsersControllerUpdateOne(id, updateCompanyUserRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CurrentUserCompanyUsersApi.currentUserCompanyUsersControllerUpdateOne']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CurrentUserCompanyUsersApi - factory interface
 * @export
 */
export const CurrentUserCompanyUsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CurrentUserCompanyUsersApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete employee account and send them a confirmation email
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserCompanyUsersControllerDeleteEmployeeAccount(id: string, options?: any): AxiosPromise<InviteEmployeeResponseDto> {
            return localVarFp.currentUserCompanyUsersControllerDeleteEmployeeAccount(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary All employees in the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserCompanyUsersControllerFindAllUsers(options?: any): AxiosPromise<GetAllCompanyUsersResponseDto> {
            return localVarFp.currentUserCompanyUsersControllerFindAllUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch employee account with roles
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserCompanyUsersControllerGetOne(id: string, options?: any): AxiosPromise<GetCompanyUserResponseDto> {
            return localVarFp.currentUserCompanyUsersControllerGetOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates unverified account and send them invitation email
         * @param {InviteEmployeeRequestDto} inviteEmployeeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserCompanyUsersControllerInviteEmployee(inviteEmployeeRequestDto: InviteEmployeeRequestDto, options?: any): AxiosPromise<InviteEmployeeResponseDto> {
            return localVarFp.currentUserCompanyUsersControllerInviteEmployee(inviteEmployeeRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch employee account with roles
         * @param {string} id 
         * @param {UpdateCompanyUserRequestDto} updateCompanyUserRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserCompanyUsersControllerUpdateOne(id: string, updateCompanyUserRequestDto: UpdateCompanyUserRequestDto, options?: any): AxiosPromise<GetCompanyUserResponseDto> {
            return localVarFp.currentUserCompanyUsersControllerUpdateOne(id, updateCompanyUserRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CurrentUserCompanyUsersApi - object-oriented interface
 * @export
 * @class CurrentUserCompanyUsersApi
 * @extends {BaseAPI}
 */
export class CurrentUserCompanyUsersApi extends BaseAPI {
    /**
     * 
     * @summary Delete employee account and send them a confirmation email
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrentUserCompanyUsersApi
     */
    public currentUserCompanyUsersControllerDeleteEmployeeAccount(id: string, options?: RawAxiosRequestConfig) {
        return CurrentUserCompanyUsersApiFp(this.configuration).currentUserCompanyUsersControllerDeleteEmployeeAccount(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary All employees in the company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrentUserCompanyUsersApi
     */
    public currentUserCompanyUsersControllerFindAllUsers(options?: RawAxiosRequestConfig) {
        return CurrentUserCompanyUsersApiFp(this.configuration).currentUserCompanyUsersControllerFindAllUsers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch employee account with roles
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrentUserCompanyUsersApi
     */
    public currentUserCompanyUsersControllerGetOne(id: string, options?: RawAxiosRequestConfig) {
        return CurrentUserCompanyUsersApiFp(this.configuration).currentUserCompanyUsersControllerGetOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates unverified account and send them invitation email
     * @param {InviteEmployeeRequestDto} inviteEmployeeRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrentUserCompanyUsersApi
     */
    public currentUserCompanyUsersControllerInviteEmployee(inviteEmployeeRequestDto: InviteEmployeeRequestDto, options?: RawAxiosRequestConfig) {
        return CurrentUserCompanyUsersApiFp(this.configuration).currentUserCompanyUsersControllerInviteEmployee(inviteEmployeeRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch employee account with roles
     * @param {string} id 
     * @param {UpdateCompanyUserRequestDto} updateCompanyUserRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrentUserCompanyUsersApi
     */
    public currentUserCompanyUsersControllerUpdateOne(id: string, updateCompanyUserRequestDto: UpdateCompanyUserRequestDto, options?: RawAxiosRequestConfig) {
        return CurrentUserCompanyUsersApiFp(this.configuration).currentUserCompanyUsersControllerUpdateOne(id, updateCompanyUserRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CurrentUserMachinesApi - axios parameter creator
 * @export
 */
export const CurrentUserMachinesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Deletes a machine from the company if it declined or pending. If it was active, this call only deactivates the machine. The machine will still be visible in the list. Inactive machines won\'t be allowed to be deleted
         * @summary Deletes a machine
         * @param {string} serialNumber The serial number of the machine
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserMachinesControllerDeleteMachine: async (serialNumber: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serialNumber' is not null or undefined
            assertParamExists('currentUserMachinesControllerDeleteMachine', 'serialNumber', serialNumber)
            const localVarPath = `/api/v1/user/me/machines/{serialNumber}`
                .replace(`{${"serialNumber"}}`, encodeURIComponent(String(serialNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns all machines
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserMachinesControllerGetAllMachines: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/user/me/machines`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a single machine
         * @param {string} serialNumber The serial number of the machine
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserMachinesControllerGetMachine: async (serialNumber: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serialNumber' is not null or undefined
            assertParamExists('currentUserMachinesControllerGetMachine', 'serialNumber', serialNumber)
            const localVarPath = `/api/v1/user/me/machines/{serialNumber}`
                .replace(`{${"serialNumber"}}`, encodeURIComponent(String(serialNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns machine shared with current user by serial number
         * @param {string} serialNumber The serial number of the machine
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserMachinesControllerGetMachineSharedWithMe: async (serialNumber: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serialNumber' is not null or undefined
            assertParamExists('currentUserMachinesControllerGetMachineSharedWithMe', 'serialNumber', serialNumber)
            const localVarPath = `/api/v1/user/me/machines/shared-with-me/{serialNumber}`
                .replace(`{${"serialNumber"}}`, encodeURIComponent(String(serialNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns all machines shared with current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserMachinesControllerGetMachinesSharedWithMe: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/user/me/machines/shared-with-me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a machine
         * @param {string} serialNumber The serial number of the machine
         * @param {UpdateMachineDto} updateMachineDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserMachinesControllerPatchMachine: async (serialNumber: string, updateMachineDto: UpdateMachineDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serialNumber' is not null or undefined
            assertParamExists('currentUserMachinesControllerPatchMachine', 'serialNumber', serialNumber)
            // verify required parameter 'updateMachineDto' is not null or undefined
            assertParamExists('currentUserMachinesControllerPatchMachine', 'updateMachineDto', updateMachineDto)
            const localVarPath = `/api/v1/user/me/machines/{serialNumber}`
                .replace(`{${"serialNumber"}}`, encodeURIComponent(String(serialNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateMachineDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The machine is created in a pending state and needs to get verified before being active.
         * @summary Creates a new machine
         * @param {CreateMachineDto} createMachineDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserMachinesControllerPostMachine: async (createMachineDto: CreateMachineDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createMachineDto' is not null or undefined
            assertParamExists('currentUserMachinesControllerPostMachine', 'createMachineDto', createMachineDto)
            const localVarPath = `/api/v1/user/me/machines`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createMachineDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates meta data for the machine shared with the user
         * @param {string} serialNumber The serial number of the machine
         * @param {UpdateMachineSharedRequestDto} updateMachineSharedRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserMachinesControllerUpdateMachineSharedWithMe: async (serialNumber: string, updateMachineSharedRequestDto: UpdateMachineSharedRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serialNumber' is not null or undefined
            assertParamExists('currentUserMachinesControllerUpdateMachineSharedWithMe', 'serialNumber', serialNumber)
            // verify required parameter 'updateMachineSharedRequestDto' is not null or undefined
            assertParamExists('currentUserMachinesControllerUpdateMachineSharedWithMe', 'updateMachineSharedRequestDto', updateMachineSharedRequestDto)
            const localVarPath = `/api/v1/user/me/machines/shared-with-me/{serialNumber}`
                .replace(`{${"serialNumber"}}`, encodeURIComponent(String(serialNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateMachineSharedRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CurrentUserMachinesApi - functional programming interface
 * @export
 */
export const CurrentUserMachinesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CurrentUserMachinesApiAxiosParamCreator(configuration)
    return {
        /**
         * Deletes a machine from the company if it declined or pending. If it was active, this call only deactivates the machine. The machine will still be visible in the list. Inactive machines won\'t be allowed to be deleted
         * @summary Deletes a machine
         * @param {string} serialNumber The serial number of the machine
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentUserMachinesControllerDeleteMachine(serialNumber: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMachineDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentUserMachinesControllerDeleteMachine(serialNumber, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CurrentUserMachinesApi.currentUserMachinesControllerDeleteMachine']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns all machines
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentUserMachinesControllerGetAllMachines(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MachineDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentUserMachinesControllerGetAllMachines(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CurrentUserMachinesApi.currentUserMachinesControllerGetAllMachines']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns a single machine
         * @param {string} serialNumber The serial number of the machine
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentUserMachinesControllerGetMachine(serialNumber: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MachineDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentUserMachinesControllerGetMachine(serialNumber, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CurrentUserMachinesApi.currentUserMachinesControllerGetMachine']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns machine shared with current user by serial number
         * @param {string} serialNumber The serial number of the machine
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentUserMachinesControllerGetMachineSharedWithMe(serialNumber: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SharedMachineDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentUserMachinesControllerGetMachineSharedWithMe(serialNumber, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CurrentUserMachinesApi.currentUserMachinesControllerGetMachineSharedWithMe']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns all machines shared with current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentUserMachinesControllerGetMachinesSharedWithMe(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SharedMachineDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentUserMachinesControllerGetMachinesSharedWithMe(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CurrentUserMachinesApi.currentUserMachinesControllerGetMachinesSharedWithMe']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Updates a machine
         * @param {string} serialNumber The serial number of the machine
         * @param {UpdateMachineDto} updateMachineDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentUserMachinesControllerPatchMachine(serialNumber: string, updateMachineDto: UpdateMachineDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MachineDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentUserMachinesControllerPatchMachine(serialNumber, updateMachineDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CurrentUserMachinesApi.currentUserMachinesControllerPatchMachine']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * The machine is created in a pending state and needs to get verified before being active.
         * @summary Creates a new machine
         * @param {CreateMachineDto} createMachineDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentUserMachinesControllerPostMachine(createMachineDto: CreateMachineDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MachineDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentUserMachinesControllerPostMachine(createMachineDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CurrentUserMachinesApi.currentUserMachinesControllerPostMachine']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Updates meta data for the machine shared with the user
         * @param {string} serialNumber The serial number of the machine
         * @param {UpdateMachineSharedRequestDto} updateMachineSharedRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentUserMachinesControllerUpdateMachineSharedWithMe(serialNumber: string, updateMachineSharedRequestDto: UpdateMachineSharedRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateMachineSharedResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentUserMachinesControllerUpdateMachineSharedWithMe(serialNumber, updateMachineSharedRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CurrentUserMachinesApi.currentUserMachinesControllerUpdateMachineSharedWithMe']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CurrentUserMachinesApi - factory interface
 * @export
 */
export const CurrentUserMachinesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CurrentUserMachinesApiFp(configuration)
    return {
        /**
         * Deletes a machine from the company if it declined or pending. If it was active, this call only deactivates the machine. The machine will still be visible in the list. Inactive machines won\'t be allowed to be deleted
         * @summary Deletes a machine
         * @param {string} serialNumber The serial number of the machine
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserMachinesControllerDeleteMachine(serialNumber: string, options?: any): AxiosPromise<GetMachineDto> {
            return localVarFp.currentUserMachinesControllerDeleteMachine(serialNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns all machines
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserMachinesControllerGetAllMachines(options?: any): AxiosPromise<Array<MachineDto>> {
            return localVarFp.currentUserMachinesControllerGetAllMachines(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a single machine
         * @param {string} serialNumber The serial number of the machine
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserMachinesControllerGetMachine(serialNumber: string, options?: any): AxiosPromise<MachineDto> {
            return localVarFp.currentUserMachinesControllerGetMachine(serialNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns machine shared with current user by serial number
         * @param {string} serialNumber The serial number of the machine
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserMachinesControllerGetMachineSharedWithMe(serialNumber: string, options?: any): AxiosPromise<SharedMachineDto> {
            return localVarFp.currentUserMachinesControllerGetMachineSharedWithMe(serialNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns all machines shared with current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserMachinesControllerGetMachinesSharedWithMe(options?: any): AxiosPromise<Array<SharedMachineDto>> {
            return localVarFp.currentUserMachinesControllerGetMachinesSharedWithMe(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a machine
         * @param {string} serialNumber The serial number of the machine
         * @param {UpdateMachineDto} updateMachineDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserMachinesControllerPatchMachine(serialNumber: string, updateMachineDto: UpdateMachineDto, options?: any): AxiosPromise<MachineDto> {
            return localVarFp.currentUserMachinesControllerPatchMachine(serialNumber, updateMachineDto, options).then((request) => request(axios, basePath));
        },
        /**
         * The machine is created in a pending state and needs to get verified before being active.
         * @summary Creates a new machine
         * @param {CreateMachineDto} createMachineDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserMachinesControllerPostMachine(createMachineDto: CreateMachineDto, options?: any): AxiosPromise<MachineDto> {
            return localVarFp.currentUserMachinesControllerPostMachine(createMachineDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates meta data for the machine shared with the user
         * @param {string} serialNumber The serial number of the machine
         * @param {UpdateMachineSharedRequestDto} updateMachineSharedRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserMachinesControllerUpdateMachineSharedWithMe(serialNumber: string, updateMachineSharedRequestDto: UpdateMachineSharedRequestDto, options?: any): AxiosPromise<UpdateMachineSharedResponseDto> {
            return localVarFp.currentUserMachinesControllerUpdateMachineSharedWithMe(serialNumber, updateMachineSharedRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CurrentUserMachinesApi - object-oriented interface
 * @export
 * @class CurrentUserMachinesApi
 * @extends {BaseAPI}
 */
export class CurrentUserMachinesApi extends BaseAPI {
    /**
     * Deletes a machine from the company if it declined or pending. If it was active, this call only deactivates the machine. The machine will still be visible in the list. Inactive machines won\'t be allowed to be deleted
     * @summary Deletes a machine
     * @param {string} serialNumber The serial number of the machine
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrentUserMachinesApi
     */
    public currentUserMachinesControllerDeleteMachine(serialNumber: string, options?: RawAxiosRequestConfig) {
        return CurrentUserMachinesApiFp(this.configuration).currentUserMachinesControllerDeleteMachine(serialNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns all machines
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrentUserMachinesApi
     */
    public currentUserMachinesControllerGetAllMachines(options?: RawAxiosRequestConfig) {
        return CurrentUserMachinesApiFp(this.configuration).currentUserMachinesControllerGetAllMachines(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a single machine
     * @param {string} serialNumber The serial number of the machine
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrentUserMachinesApi
     */
    public currentUserMachinesControllerGetMachine(serialNumber: string, options?: RawAxiosRequestConfig) {
        return CurrentUserMachinesApiFp(this.configuration).currentUserMachinesControllerGetMachine(serialNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns machine shared with current user by serial number
     * @param {string} serialNumber The serial number of the machine
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrentUserMachinesApi
     */
    public currentUserMachinesControllerGetMachineSharedWithMe(serialNumber: string, options?: RawAxiosRequestConfig) {
        return CurrentUserMachinesApiFp(this.configuration).currentUserMachinesControllerGetMachineSharedWithMe(serialNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns all machines shared with current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrentUserMachinesApi
     */
    public currentUserMachinesControllerGetMachinesSharedWithMe(options?: RawAxiosRequestConfig) {
        return CurrentUserMachinesApiFp(this.configuration).currentUserMachinesControllerGetMachinesSharedWithMe(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a machine
     * @param {string} serialNumber The serial number of the machine
     * @param {UpdateMachineDto} updateMachineDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrentUserMachinesApi
     */
    public currentUserMachinesControllerPatchMachine(serialNumber: string, updateMachineDto: UpdateMachineDto, options?: RawAxiosRequestConfig) {
        return CurrentUserMachinesApiFp(this.configuration).currentUserMachinesControllerPatchMachine(serialNumber, updateMachineDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The machine is created in a pending state and needs to get verified before being active.
     * @summary Creates a new machine
     * @param {CreateMachineDto} createMachineDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrentUserMachinesApi
     */
    public currentUserMachinesControllerPostMachine(createMachineDto: CreateMachineDto, options?: RawAxiosRequestConfig) {
        return CurrentUserMachinesApiFp(this.configuration).currentUserMachinesControllerPostMachine(createMachineDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates meta data for the machine shared with the user
     * @param {string} serialNumber The serial number of the machine
     * @param {UpdateMachineSharedRequestDto} updateMachineSharedRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrentUserMachinesApi
     */
    public currentUserMachinesControllerUpdateMachineSharedWithMe(serialNumber: string, updateMachineSharedRequestDto: UpdateMachineSharedRequestDto, options?: RawAxiosRequestConfig) {
        return CurrentUserMachinesApiFp(this.configuration).currentUserMachinesControllerUpdateMachineSharedWithMe(serialNumber, updateMachineSharedRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CurrentUserMachinesSharingContractsApi - axios parameter creator
 * @export
 */
export const CurrentUserMachinesSharingContractsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Approves machine sharing contract as owner/recipient, depending on what party current user belongs to in the sharing contract
         * @summary Approves machine sharing contract
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserMachineSharingContractsControllerApproveMachineSharingContract: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('currentUserMachineSharingContractsControllerApproveMachineSharingContract', 'id', id)
            const localVarPath = `/api/user/me/machines-sharing-contracts/{id}/approve`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Declines machine sharing contract as owner/recipient, depending on what party current user belongs to
         * @summary Declines machine sharing contract
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserMachineSharingContractsControllerDeclineMachineSharingContract: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('currentUserMachineSharingContractsControllerDeclineMachineSharingContract', 'id', id)
            const localVarPath = `/api/user/me/machines-sharing-contracts/{id}/decline`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all machine sharing contracts that are pending for current user approval, be that recipient or owner side approval
         * @summary Returns all machine sharing contracts that are pending for current user approval approval
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserMachineSharingContractsControllerGetPendingMachineShares: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/me/machines-sharing-contracts/pending-approval`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns sharing contracts of machines owned by current user. Supports filtration by recipient or by machine serial
         * @summary Returns sharing contracts of machines owned by current user
         * @param {string} [recipientCompanyBR] Pass to filter machine sharing contracts by recipient\&#39;s company business relation id
         * @param {string} [machineSerialNumber] Pass to filter machine sharing contracts by machine serial number
         * @param {boolean} [isOwnerConsentGive] 
         * @param {boolean} [isRecipientConsentGiven] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserMachineSharingContractsControllerListOwnerMachineSharingContracts: async (recipientCompanyBR?: string, machineSerialNumber?: string, isOwnerConsentGive?: boolean, isRecipientConsentGiven?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/me/machines-sharing-contracts/as-owner`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (recipientCompanyBR !== undefined) {
                localVarQueryParameter['recipientCompanyBR'] = recipientCompanyBR;
            }

            if (machineSerialNumber !== undefined) {
                localVarQueryParameter['machineSerialNumber'] = machineSerialNumber;
            }

            if (isOwnerConsentGive !== undefined) {
                localVarQueryParameter['isOwnerConsentGive'] = isOwnerConsentGive;
            }

            if (isRecipientConsentGiven !== undefined) {
                localVarQueryParameter['isRecipientConsentGiven'] = isRecipientConsentGiven;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates machines sharing contracts as \"owner\" party \"offering\" sharing contracts to \"recipient\"
         * @summary Creates machines sharing contracts offers
         * @param {OfferMachineSharingContractRequestDto} offerMachineSharingContractRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserMachineSharingContractsControllerOfferMachineSharingContracts: async (offerMachineSharingContractRequestDto: OfferMachineSharingContractRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'offerMachineSharingContractRequestDto' is not null or undefined
            assertParamExists('currentUserMachineSharingContractsControllerOfferMachineSharingContracts', 'offerMachineSharingContractRequestDto', offerMachineSharingContractRequestDto)
            const localVarPath = `/api/user/me/machines-sharing-contracts/offer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(offerMachineSharingContractRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Probes if possible to offer machine sharing to specified counter party
         * @summary Probes for machines sharing contracts offer availability
         * @param {string | null} [recipientBR] BR of recipient company. Either recipientBR or recipientEmail has to be passed to find the company.
         * @param {string | null} [recipientEmail] Email of the recipient company representative. Either recipientBR or recipientEmail has to be passed to find the company.
         * @param {Array<string> | null} [machinesSerialNumbers] Serial numbers of the machines to share
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserMachineSharingContractsControllerProbeMachineSharingContractOffer: async (recipientBR?: string | null, recipientEmail?: string | null, machinesSerialNumbers?: Array<string> | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/me/machines-sharing-contracts/probe-offer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (recipientBR !== undefined) {
                localVarQueryParameter['recipientBR'] = recipientBR;
            }

            if (recipientEmail !== undefined) {
                localVarQueryParameter['recipientEmail'] = recipientEmail;
            }

            if (machinesSerialNumbers) {
                localVarQueryParameter['machinesSerialNumbers'] = machinesSerialNumbers;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes machine sharing contract
         * @summary Removes machine sharing contract
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserMachineSharingContractsControllerRemoveMachineSharingContract: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('currentUserMachineSharingContractsControllerRemoveMachineSharingContract', 'id', id)
            const localVarPath = `/api/user/me/machines-sharing-contracts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Bulk removal of machine sharing contracts
         * @summary Bulk removal of machine sharing contracts
         * @param {BulkRemovalMachineSharingContractsRequestDto} bulkRemovalMachineSharingContractsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserMachineSharingContractsControllerRemoveMachineSharingContractBulk: async (bulkRemovalMachineSharingContractsRequestDto: BulkRemovalMachineSharingContractsRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bulkRemovalMachineSharingContractsRequestDto' is not null or undefined
            assertParamExists('currentUserMachineSharingContractsControllerRemoveMachineSharingContractBulk', 'bulkRemovalMachineSharingContractsRequestDto', bulkRemovalMachineSharingContractsRequestDto)
            const localVarPath = `/api/user/me/machines-sharing-contracts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bulkRemovalMachineSharingContractsRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates machine sharing contract as \"recipient\" party \"requesting\" sharing from \"owner\"
         * @summary Creates machine sharing contract request
         * @param {RequestMachineSharingContractRequestDto} requestMachineSharingContractRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserMachineSharingContractsControllerRequestMachineSharingContracts: async (requestMachineSharingContractRequestDto: RequestMachineSharingContractRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestMachineSharingContractRequestDto' is not null or undefined
            assertParamExists('currentUserMachineSharingContractsControllerRequestMachineSharingContracts', 'requestMachineSharingContractRequestDto', requestMachineSharingContractRequestDto)
            const localVarPath = `/api/user/me/machines-sharing-contracts/request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestMachineSharingContractRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CurrentUserMachinesSharingContractsApi - functional programming interface
 * @export
 */
export const CurrentUserMachinesSharingContractsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CurrentUserMachinesSharingContractsApiAxiosParamCreator(configuration)
    return {
        /**
         * Approves machine sharing contract as owner/recipient, depending on what party current user belongs to in the sharing contract
         * @summary Approves machine sharing contract
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentUserMachineSharingContractsControllerApproveMachineSharingContract(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApproveMachineSharingContractResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentUserMachineSharingContractsControllerApproveMachineSharingContract(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CurrentUserMachinesSharingContractsApi.currentUserMachineSharingContractsControllerApproveMachineSharingContract']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Declines machine sharing contract as owner/recipient, depending on what party current user belongs to
         * @summary Declines machine sharing contract
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentUserMachineSharingContractsControllerDeclineMachineSharingContract(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentUserMachineSharingContractsControllerDeclineMachineSharingContract(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CurrentUserMachinesSharingContractsApi.currentUserMachineSharingContractsControllerDeclineMachineSharingContract']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns all machine sharing contracts that are pending for current user approval, be that recipient or owner side approval
         * @summary Returns all machine sharing contracts that are pending for current user approval approval
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentUserMachineSharingContractsControllerGetPendingMachineShares(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListPendingApprovalMachineSharingContractsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentUserMachineSharingContractsControllerGetPendingMachineShares(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CurrentUserMachinesSharingContractsApi.currentUserMachineSharingContractsControllerGetPendingMachineShares']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns sharing contracts of machines owned by current user. Supports filtration by recipient or by machine serial
         * @summary Returns sharing contracts of machines owned by current user
         * @param {string} [recipientCompanyBR] Pass to filter machine sharing contracts by recipient\&#39;s company business relation id
         * @param {string} [machineSerialNumber] Pass to filter machine sharing contracts by machine serial number
         * @param {boolean} [isOwnerConsentGive] 
         * @param {boolean} [isRecipientConsentGiven] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentUserMachineSharingContractsControllerListOwnerMachineSharingContracts(recipientCompanyBR?: string, machineSerialNumber?: string, isOwnerConsentGive?: boolean, isRecipientConsentGiven?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListOwnerMachineSharingContractsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentUserMachineSharingContractsControllerListOwnerMachineSharingContracts(recipientCompanyBR, machineSerialNumber, isOwnerConsentGive, isRecipientConsentGiven, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CurrentUserMachinesSharingContractsApi.currentUserMachineSharingContractsControllerListOwnerMachineSharingContracts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Creates machines sharing contracts as \"owner\" party \"offering\" sharing contracts to \"recipient\"
         * @summary Creates machines sharing contracts offers
         * @param {OfferMachineSharingContractRequestDto} offerMachineSharingContractRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentUserMachineSharingContractsControllerOfferMachineSharingContracts(offerMachineSharingContractRequestDto: OfferMachineSharingContractRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferMachineSharingContractResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentUserMachineSharingContractsControllerOfferMachineSharingContracts(offerMachineSharingContractRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CurrentUserMachinesSharingContractsApi.currentUserMachineSharingContractsControllerOfferMachineSharingContracts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Probes if possible to offer machine sharing to specified counter party
         * @summary Probes for machines sharing contracts offer availability
         * @param {string | null} [recipientBR] BR of recipient company. Either recipientBR or recipientEmail has to be passed to find the company.
         * @param {string | null} [recipientEmail] Email of the recipient company representative. Either recipientBR or recipientEmail has to be passed to find the company.
         * @param {Array<string> | null} [machinesSerialNumbers] Serial numbers of the machines to share
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentUserMachineSharingContractsControllerProbeMachineSharingContractOffer(recipientBR?: string | null, recipientEmail?: string | null, machinesSerialNumbers?: Array<string> | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProbeOfferMachineSharingContractResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentUserMachineSharingContractsControllerProbeMachineSharingContractOffer(recipientBR, recipientEmail, machinesSerialNumbers, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CurrentUserMachinesSharingContractsApi.currentUserMachineSharingContractsControllerProbeMachineSharingContractOffer']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Removes machine sharing contract
         * @summary Removes machine sharing contract
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentUserMachineSharingContractsControllerRemoveMachineSharingContract(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentUserMachineSharingContractsControllerRemoveMachineSharingContract(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CurrentUserMachinesSharingContractsApi.currentUserMachineSharingContractsControllerRemoveMachineSharingContract']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Bulk removal of machine sharing contracts
         * @summary Bulk removal of machine sharing contracts
         * @param {BulkRemovalMachineSharingContractsRequestDto} bulkRemovalMachineSharingContractsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentUserMachineSharingContractsControllerRemoveMachineSharingContractBulk(bulkRemovalMachineSharingContractsRequestDto: BulkRemovalMachineSharingContractsRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentUserMachineSharingContractsControllerRemoveMachineSharingContractBulk(bulkRemovalMachineSharingContractsRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CurrentUserMachinesSharingContractsApi.currentUserMachineSharingContractsControllerRemoveMachineSharingContractBulk']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Creates machine sharing contract as \"recipient\" party \"requesting\" sharing from \"owner\"
         * @summary Creates machine sharing contract request
         * @param {RequestMachineSharingContractRequestDto} requestMachineSharingContractRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentUserMachineSharingContractsControllerRequestMachineSharingContracts(requestMachineSharingContractRequestDto: RequestMachineSharingContractRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RequestMachineSharingContractResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentUserMachineSharingContractsControllerRequestMachineSharingContracts(requestMachineSharingContractRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CurrentUserMachinesSharingContractsApi.currentUserMachineSharingContractsControllerRequestMachineSharingContracts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CurrentUserMachinesSharingContractsApi - factory interface
 * @export
 */
export const CurrentUserMachinesSharingContractsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CurrentUserMachinesSharingContractsApiFp(configuration)
    return {
        /**
         * Approves machine sharing contract as owner/recipient, depending on what party current user belongs to in the sharing contract
         * @summary Approves machine sharing contract
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserMachineSharingContractsControllerApproveMachineSharingContract(id: string, options?: any): AxiosPromise<ApproveMachineSharingContractResponseDto> {
            return localVarFp.currentUserMachineSharingContractsControllerApproveMachineSharingContract(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Declines machine sharing contract as owner/recipient, depending on what party current user belongs to
         * @summary Declines machine sharing contract
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserMachineSharingContractsControllerDeclineMachineSharingContract(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.currentUserMachineSharingContractsControllerDeclineMachineSharingContract(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all machine sharing contracts that are pending for current user approval, be that recipient or owner side approval
         * @summary Returns all machine sharing contracts that are pending for current user approval approval
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserMachineSharingContractsControllerGetPendingMachineShares(options?: any): AxiosPromise<ListPendingApprovalMachineSharingContractsResponseDto> {
            return localVarFp.currentUserMachineSharingContractsControllerGetPendingMachineShares(options).then((request) => request(axios, basePath));
        },
        /**
         * Returns sharing contracts of machines owned by current user. Supports filtration by recipient or by machine serial
         * @summary Returns sharing contracts of machines owned by current user
         * @param {string} [recipientCompanyBR] Pass to filter machine sharing contracts by recipient\&#39;s company business relation id
         * @param {string} [machineSerialNumber] Pass to filter machine sharing contracts by machine serial number
         * @param {boolean} [isOwnerConsentGive] 
         * @param {boolean} [isRecipientConsentGiven] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserMachineSharingContractsControllerListOwnerMachineSharingContracts(recipientCompanyBR?: string, machineSerialNumber?: string, isOwnerConsentGive?: boolean, isRecipientConsentGiven?: boolean, options?: any): AxiosPromise<ListOwnerMachineSharingContractsResponseDto> {
            return localVarFp.currentUserMachineSharingContractsControllerListOwnerMachineSharingContracts(recipientCompanyBR, machineSerialNumber, isOwnerConsentGive, isRecipientConsentGiven, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates machines sharing contracts as \"owner\" party \"offering\" sharing contracts to \"recipient\"
         * @summary Creates machines sharing contracts offers
         * @param {OfferMachineSharingContractRequestDto} offerMachineSharingContractRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserMachineSharingContractsControllerOfferMachineSharingContracts(offerMachineSharingContractRequestDto: OfferMachineSharingContractRequestDto, options?: any): AxiosPromise<OfferMachineSharingContractResponseDto> {
            return localVarFp.currentUserMachineSharingContractsControllerOfferMachineSharingContracts(offerMachineSharingContractRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Probes if possible to offer machine sharing to specified counter party
         * @summary Probes for machines sharing contracts offer availability
         * @param {string | null} [recipientBR] BR of recipient company. Either recipientBR or recipientEmail has to be passed to find the company.
         * @param {string | null} [recipientEmail] Email of the recipient company representative. Either recipientBR or recipientEmail has to be passed to find the company.
         * @param {Array<string> | null} [machinesSerialNumbers] Serial numbers of the machines to share
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserMachineSharingContractsControllerProbeMachineSharingContractOffer(recipientBR?: string | null, recipientEmail?: string | null, machinesSerialNumbers?: Array<string> | null, options?: any): AxiosPromise<ProbeOfferMachineSharingContractResponseDto> {
            return localVarFp.currentUserMachineSharingContractsControllerProbeMachineSharingContractOffer(recipientBR, recipientEmail, machinesSerialNumbers, options).then((request) => request(axios, basePath));
        },
        /**
         * Removes machine sharing contract
         * @summary Removes machine sharing contract
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserMachineSharingContractsControllerRemoveMachineSharingContract(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.currentUserMachineSharingContractsControllerRemoveMachineSharingContract(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Bulk removal of machine sharing contracts
         * @summary Bulk removal of machine sharing contracts
         * @param {BulkRemovalMachineSharingContractsRequestDto} bulkRemovalMachineSharingContractsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserMachineSharingContractsControllerRemoveMachineSharingContractBulk(bulkRemovalMachineSharingContractsRequestDto: BulkRemovalMachineSharingContractsRequestDto, options?: any): AxiosPromise<void> {
            return localVarFp.currentUserMachineSharingContractsControllerRemoveMachineSharingContractBulk(bulkRemovalMachineSharingContractsRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates machine sharing contract as \"recipient\" party \"requesting\" sharing from \"owner\"
         * @summary Creates machine sharing contract request
         * @param {RequestMachineSharingContractRequestDto} requestMachineSharingContractRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserMachineSharingContractsControllerRequestMachineSharingContracts(requestMachineSharingContractRequestDto: RequestMachineSharingContractRequestDto, options?: any): AxiosPromise<RequestMachineSharingContractResponseDto> {
            return localVarFp.currentUserMachineSharingContractsControllerRequestMachineSharingContracts(requestMachineSharingContractRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CurrentUserMachinesSharingContractsApi - object-oriented interface
 * @export
 * @class CurrentUserMachinesSharingContractsApi
 * @extends {BaseAPI}
 */
export class CurrentUserMachinesSharingContractsApi extends BaseAPI {
    /**
     * Approves machine sharing contract as owner/recipient, depending on what party current user belongs to in the sharing contract
     * @summary Approves machine sharing contract
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrentUserMachinesSharingContractsApi
     */
    public currentUserMachineSharingContractsControllerApproveMachineSharingContract(id: string, options?: RawAxiosRequestConfig) {
        return CurrentUserMachinesSharingContractsApiFp(this.configuration).currentUserMachineSharingContractsControllerApproveMachineSharingContract(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Declines machine sharing contract as owner/recipient, depending on what party current user belongs to
     * @summary Declines machine sharing contract
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrentUserMachinesSharingContractsApi
     */
    public currentUserMachineSharingContractsControllerDeclineMachineSharingContract(id: string, options?: RawAxiosRequestConfig) {
        return CurrentUserMachinesSharingContractsApiFp(this.configuration).currentUserMachineSharingContractsControllerDeclineMachineSharingContract(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all machine sharing contracts that are pending for current user approval, be that recipient or owner side approval
     * @summary Returns all machine sharing contracts that are pending for current user approval approval
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrentUserMachinesSharingContractsApi
     */
    public currentUserMachineSharingContractsControllerGetPendingMachineShares(options?: RawAxiosRequestConfig) {
        return CurrentUserMachinesSharingContractsApiFp(this.configuration).currentUserMachineSharingContractsControllerGetPendingMachineShares(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns sharing contracts of machines owned by current user. Supports filtration by recipient or by machine serial
     * @summary Returns sharing contracts of machines owned by current user
     * @param {string} [recipientCompanyBR] Pass to filter machine sharing contracts by recipient\&#39;s company business relation id
     * @param {string} [machineSerialNumber] Pass to filter machine sharing contracts by machine serial number
     * @param {boolean} [isOwnerConsentGive] 
     * @param {boolean} [isRecipientConsentGiven] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrentUserMachinesSharingContractsApi
     */
    public currentUserMachineSharingContractsControllerListOwnerMachineSharingContracts(recipientCompanyBR?: string, machineSerialNumber?: string, isOwnerConsentGive?: boolean, isRecipientConsentGiven?: boolean, options?: RawAxiosRequestConfig) {
        return CurrentUserMachinesSharingContractsApiFp(this.configuration).currentUserMachineSharingContractsControllerListOwnerMachineSharingContracts(recipientCompanyBR, machineSerialNumber, isOwnerConsentGive, isRecipientConsentGiven, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates machines sharing contracts as \"owner\" party \"offering\" sharing contracts to \"recipient\"
     * @summary Creates machines sharing contracts offers
     * @param {OfferMachineSharingContractRequestDto} offerMachineSharingContractRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrentUserMachinesSharingContractsApi
     */
    public currentUserMachineSharingContractsControllerOfferMachineSharingContracts(offerMachineSharingContractRequestDto: OfferMachineSharingContractRequestDto, options?: RawAxiosRequestConfig) {
        return CurrentUserMachinesSharingContractsApiFp(this.configuration).currentUserMachineSharingContractsControllerOfferMachineSharingContracts(offerMachineSharingContractRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Probes if possible to offer machine sharing to specified counter party
     * @summary Probes for machines sharing contracts offer availability
     * @param {string | null} [recipientBR] BR of recipient company. Either recipientBR or recipientEmail has to be passed to find the company.
     * @param {string | null} [recipientEmail] Email of the recipient company representative. Either recipientBR or recipientEmail has to be passed to find the company.
     * @param {Array<string> | null} [machinesSerialNumbers] Serial numbers of the machines to share
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrentUserMachinesSharingContractsApi
     */
    public currentUserMachineSharingContractsControllerProbeMachineSharingContractOffer(recipientBR?: string | null, recipientEmail?: string | null, machinesSerialNumbers?: Array<string> | null, options?: RawAxiosRequestConfig) {
        return CurrentUserMachinesSharingContractsApiFp(this.configuration).currentUserMachineSharingContractsControllerProbeMachineSharingContractOffer(recipientBR, recipientEmail, machinesSerialNumbers, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes machine sharing contract
     * @summary Removes machine sharing contract
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrentUserMachinesSharingContractsApi
     */
    public currentUserMachineSharingContractsControllerRemoveMachineSharingContract(id: string, options?: RawAxiosRequestConfig) {
        return CurrentUserMachinesSharingContractsApiFp(this.configuration).currentUserMachineSharingContractsControllerRemoveMachineSharingContract(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Bulk removal of machine sharing contracts
     * @summary Bulk removal of machine sharing contracts
     * @param {BulkRemovalMachineSharingContractsRequestDto} bulkRemovalMachineSharingContractsRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrentUserMachinesSharingContractsApi
     */
    public currentUserMachineSharingContractsControllerRemoveMachineSharingContractBulk(bulkRemovalMachineSharingContractsRequestDto: BulkRemovalMachineSharingContractsRequestDto, options?: RawAxiosRequestConfig) {
        return CurrentUserMachinesSharingContractsApiFp(this.configuration).currentUserMachineSharingContractsControllerRemoveMachineSharingContractBulk(bulkRemovalMachineSharingContractsRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates machine sharing contract as \"recipient\" party \"requesting\" sharing from \"owner\"
     * @summary Creates machine sharing contract request
     * @param {RequestMachineSharingContractRequestDto} requestMachineSharingContractRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrentUserMachinesSharingContractsApi
     */
    public currentUserMachineSharingContractsControllerRequestMachineSharingContracts(requestMachineSharingContractRequestDto: RequestMachineSharingContractRequestDto, options?: RawAxiosRequestConfig) {
        return CurrentUserMachinesSharingContractsApiFp(this.configuration).currentUserMachineSharingContractsControllerRequestMachineSharingContracts(requestMachineSharingContractRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MachinesApi - axios parameter creator
 * @export
 */
export const MachinesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get machine by machine serial number with list of validated (active/inactive) ownerships
         * @param {string} serialNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        machinesApiControllerGetMachineWithOwnerships: async (serialNumber: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serialNumber' is not null or undefined
            assertParamExists('machinesApiControllerGetMachineWithOwnerships', 'serialNumber', serialNumber)
            const localVarPath = `/api/user/machines/{serialNumber}/with-ownerships`
                .replace(`{${"serialNumber"}}`, encodeURIComponent(String(serialNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MachinesApi - functional programming interface
 * @export
 */
export const MachinesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MachinesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get machine by machine serial number with list of validated (active/inactive) ownerships
         * @param {string} serialNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async machinesApiControllerGetMachineWithOwnerships(serialNumber: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MachineWithOwnershipsGetResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.machinesApiControllerGetMachineWithOwnerships(serialNumber, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MachinesApi.machinesApiControllerGetMachineWithOwnerships']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MachinesApi - factory interface
 * @export
 */
export const MachinesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MachinesApiFp(configuration)
    return {
        /**
         * 
         * @summary Get machine by machine serial number with list of validated (active/inactive) ownerships
         * @param {string} serialNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        machinesApiControllerGetMachineWithOwnerships(serialNumber: string, options?: any): AxiosPromise<MachineWithOwnershipsGetResponseDto> {
            return localVarFp.machinesApiControllerGetMachineWithOwnerships(serialNumber, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MachinesApi - object-oriented interface
 * @export
 * @class MachinesApi
 * @extends {BaseAPI}
 */
export class MachinesApi extends BaseAPI {
    /**
     * 
     * @summary Get machine by machine serial number with list of validated (active/inactive) ownerships
     * @param {string} serialNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MachinesApi
     */
    public machinesApiControllerGetMachineWithOwnerships(serialNumber: string, options?: RawAxiosRequestConfig) {
        return MachinesApiFp(this.configuration).machinesApiControllerGetMachineWithOwnerships(serialNumber, options).then((request) => request(this.axios, this.basePath));
    }
}



