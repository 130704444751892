import axios from 'axios';
import { environment } from '~/environments';
import { MachinesApi } from '~/gen/grid-admin';
import {
  CurrentUserApi,
  CurrentUserCompanyApi,
  CurrentUserCompanyUsersApi,
  CurrentUserMachinesApi,
  CurrentUserMachinesSharingContractsApi,
  MachinesApi as GridUserMachines,
} from '~/gen/grid-user';

const basePath = environment.gridUrl;

//TODO: These names are confusing, rename (?), there are three machines!
export const userGridApi = {
  company: new CurrentUserCompanyApi(undefined, basePath, axios),
  companyUsers: new CurrentUserCompanyUsersApi(undefined, basePath, axios),
  machineSharingContracts: new CurrentUserMachinesSharingContractsApi(
    undefined,
    basePath,
    axios,
  ),
  machines: new GridUserMachines(undefined, basePath, axios),
  machinesApi: new CurrentUserMachinesApi(undefined, basePath, axios),
  me: new CurrentUserApi(undefined, basePath, axios),
};

export const adminGridApi = {
  machines: new MachinesApi(undefined, basePath),
};
